import OAuth2PasswordGrant from 'ember-simple-auth/authenticators/oauth2-password-grant';
import ENV from 'agropur-client/config/environment';
import {

    merge,
  } from '@ember/polyfills';
import RSVP from 'rsvp';
import {isEmpty} from '@ember/utils';
import $ from 'jquery';

export default OAuth2PasswordGrant.extend({
    serverTokenEndpoint: `${ENV.APP.OPTIONSHOP_HOST}/oauth/token`,
    serverTokenRevocationEndpoint: `${ENV.APP.OPTIONSHOP_HOST}/logout`,

    /**
    Makes a request to the OAuth 2.0 server.

    @method makeRequest
    @param {String} url The request URL
    @param {Object} data The request data
    @param {Object} headers Additional headers to send in request
    @return {Promise} A promise that resolves with the response object
    @protected
  */
  makeRequest(url, data, headers = {}) {
    headers['Content-Type'] = headers['Content-Type'] ? headers['Content-Type'] : 'application/x-www-form-urlencoded';

    const body = Object.keys(data).map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
    }).join('&');

    const options = {
      body,
      headers,
      method: 'POST'
    };

    const clientIdHeader = this.get('_clientIdHeader');
    if (!isEmpty(clientIdHeader)) {
      merge(options.headers, clientIdHeader);
    }

    return new RSVP.Promise((resolve, reject) => {
      fetch(url, options).then((response) => {
        response.text().then((text) => {
          try {
            let json = JSON.parse(text);
            if (!response.ok) {
              response.responseJSON = json;
              reject(response);
            } else {

              $.ajax({
                url     : `${ENV.APP.HOST}/commercial_api/api/users/me`,
                headers : {
                  'Authorization': 'Bearer ' + json.access_token
                }
              }).then((payload, type, resp) => {
                const token = resp.getResponseHeader('X-Auth-Token');

                let r = {
                  auth: token,
                  access_token: json.access_token,
                  fullName: `${payload.firstName} ${payload.lastName}`,
                  userId: payload.id
                }

                resolve(r);
              });


              // resolve(json);
            }
          } catch (SyntaxError) {
            response.responseText = text;
            reject(response);
          }
        });
      }).catch(reject);
    });
  },
});