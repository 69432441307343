import ENV from 'agropur-client/config/environment';
import Component from '@ember/component';
import { Promise } from 'rsvp';
import $ from 'jquery';

const { BRAND } = ENV;

export default Component.extend({

  containerId: 'main-chart',

  getColorPattern() {
    return this.get('colorPattern') ? this.get('colorPattern') : [BRAND.primaryColor, BRAND.secondaryColor, '#0074D9', '#7FDBFF']; // Agropur
  },

  init(...args) {
    this._super(...args);
  },

  didInsertElement() {
    this._super();
    this.renderData();
  },

  _initChart() {
    return new Promise(function(resolve, reject) {
      if (!this.get('chart') && $(`#${this.get('containerId')}`).length > 0) {


        // eslint-disable-next-line no-undef
        this.set('chart', c3.generate({
          bindto : `#${this.get('containerId')}`,
          oninit : resolve,
          data   : {
            drag: {
              enabled  : this.get('draggable'),
              tick     : this.get('drag_tick'),
              callback : this.get('onDragEnd'),
              scope    : this.get('scope')
            },
            keys: {
              x     : 'x',
              value : ['value'] // not working
            },
            selection: {
              draggable : false,
              enabled   : false,
              multiple  : false
            },
            onclick() {

            },
            x       : 'x',
            columns : [],
            type    : 'spline',
            empty   : {
              label: {
                text: 'Loading data...'
              }
            }
          },
          color: {
            pattern: this.getColorPattern()
          },
          spline: {
            interpolation: {
              type: 'monotone'
            }
          },
          transition: {
            duration: 500
          },
          zoom: {
            enabled: false
          },
          point: {
            show : this.get('showPoints'),
            r    : this.get('pointRadius')
          },
          grid: {
            lines: {
              front: false
            },
            x: {
              show: true
              // lines : quarters
            },
            y: {
              show  : true,
              lines : [
                { value: 0, class: 'baseline' }
              ]
            }
          },
          legend: {
            // hide  : true,
            show     : this.get('showlegend') !== undefined ? this.get('showlegend') : true,
            position : 'bottom',
            inset    : {
              anchor : 'top-right',
              step   : 1,
              x      : 0,
              y      : 0
            }
          },
          axis: {
            x: {
              type : 'timeseries',
              tick : {
                count   : this.get('xTickCount'),
                culling : {
                  max: this.get('xMaxCulling')
                },
                // culling: true,
                // format: function (x) { return x.getFullYear(); }
                format: '%b %Y' // format string is also available for timeseries data
              }
            },
            y: {
              // default: [-1,1],
              max  : this.get('maxYValue'),
              min  : this.get('minYValue'),
              tick : {
                count   : this.get('yTickCount'),
                // values: [ -3, -2, -1, 0, 1, 2, 3],
                // format: d3.format('$,')
                culling : {
                  min : 10,
                  max : 100
                },
                fit    : true,
                format : function(d) {
                  if (this.get('showPercentage')) {
                    return `${d.toFixed(0)}%`;
                  }
                  if (this.get('cwt') === 'false') {
                    // eslint-disable-next-line no-undef
                    return numeral(d).format('$0,0.0000');
                  }
                  if (this.get('cwt') === 'true') {
                    // eslint-disable-next-line no-undef
                    return numeral(d).format('$0,0.00');
                  }
                  if (d === 0 || d > 100) {
                    // eslint-disable-next-line no-undef
                    return numeral(d).format('$0,0');
                  }
                  // eslint-disable-next-line no-undef
                  return numeral(d).format('$0,0.00');
                }.bind(this)
              }
            }
          }
        }));
      } else {
        reject();
      }
    }.bind(this));
  },

  // eslint-disable-next-line ember/no-function-prototype-extensions
  renderData: function() {
    if (this.get('chart')) {
      this.get('chart').load(this.get('data'));
    } else {
      this._initChart().then(function() {
        this.renderData();
      }.bind(this));
    }
  }.observes('data.[]')

});
