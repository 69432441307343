import { computed } from '@ember/object';
import Controller from '@ember/controller';

import PeriodMixin from 'agropur-client/mixins/period';
import DisplayModeMixin from 'agropur-client/mixins/display-mode';

import { A } from '@ember/array';


export default Controller.extend(PeriodMixin, DisplayModeMixin, {
  newPositionQuantity: null,
  newTicketNumber: null,

  init(){
    this._super(...arguments);
    this.set('selectedMonths', A());
  },

  checkedMonths: computed.filterBy('model.selectableMonths', 'checked', true),

  partialMonthsSelected: computed('checkedMonths.@each.checked', function(){
    return this.checkedMonths.length > 0 && this.checkedMonths.length < this.model.months.length
  }),

  allMonthsSelected: computed('checkedMonths.@each.checked', function(){
    return this.checkedMonths.length == this.model.months.length;
  }),

  clearNewPosition: function(){
    this.set('newPositionQuantity', null);
    this.set('newTicketNumber', null);
  },

  actions: {
    saveRecords() {
      const list     = this.get('checkedMonths');
      const quantity = this.get('newPositionQuantity');
      const instrument_id = this.get('newTicketNumber');
      let month;

      if (list.length != 0) {

        list.forEach((el) => {
          month = el.get('month');
          const e = this.get('store').createRecord('manual-position', {
            positionType: 'PHYSICAL',
            type: 'FORWARD',
            price: month.get('agropurBasisInstrument.price'),
            quantity,
            long: false,
            instrument_id
          });

          e.set('month', month);
          e.set('product_id', 'BASIS');
          e.set('user_id', this.model.currentUserId);

          e.save();
        });

        this.toggleProperty('showPositionsModal');
        this.checkedMonths.forEach((m) => {
          m.set('checked', false);
        });
        this.clearNewPosition();

      }
    },

    toggleCreateModal() {
      this.clearNewPosition();

      this.toggleProperty('showPositionsModal');
    },


    cancelPositionsModal() {
      this.clearNewPosition();
      this.checkedMonths.forEach((m) => {
        m.set('checked', false);
      });
      this.toggleProperty('showPositionsModal');

    },


    updateAllSelectedMonths(){

      if(this.checkedMonths.length > 0 && !this.partialMonthsSelected){
        this.checkedMonths.forEach((m) => {
          m.set('checked', false);
        });
      } else {
        this.model.selectableMonths.forEach((m) => {
          m.set('checked', true);
        });
      }
    }
  }

});
