import Controller from '@ember/controller';
import { computed } from '@ember/object';

export default Controller.extend({

  newModel: null,

  newPositionType: 'FORWARD',
  newPositionPrice: null,
  newPositionStrike: null,
  newPositionQuantity: null,
  newPositionLong: null,

  filteredPhysicalPositions: computed('model.{month,manualPositions.@each}', function() {
    return this.model.manualPositions.filter((m) => {
      return m.month.get('id') == this.model.month.id;
    })
  }),

  actions: {
    saveRecord() {
      const rec = this.get('newModel');
      rec.set('long', rec.long === 'true');
      rec.save();
      this.toggleProperty('showPositionModal');
      this.send('refreshModel');
    },

    toggleCreateModal() {

      this.set('newModel', this.get('store').createRecord('manual-position', {
        positionType: 'PHYSICAL',
        type: 'FORWARD',
        product_id: 'DC',
        month_id: this.model.month,
        userId: this.model.currentUserId
      }));

      this.toggleProperty('showPositionModal');
    },


    cancelPositionModal() {
      this.newModel.rollbackAttributes();
      this.toggleProperty('showPositionModal');
    },

    editManualPosition(position) {
      this.set('newModel', position);
      this.toggleProperty('showPositionModal');
    },

    deleteManualPosition(position) {
      position.destroyRecord();
    },
  }
});
