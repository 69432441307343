import DS from 'ember-data';
import { isPresent } from '@ember/utils';
import { computed } from '@ember/object';

const { Model, attr, belongsTo, hasMany } = DS;

export default Model.extend({
  slug           : attr('string'), // TODO: Remove - Not used
  producerId     : attr('number'),
  name           : attr('string'),
  vaultType      : attr('string'), // TODO: Remove - Always Agropur
  pricingModel   : attr('string'), // TODO: Remove - Always Agropur
  productionType : attr('string'), // TODO: Remove - Always total

  isClickwrapAccepted: attr('boolean'),

  addressLine1 : attr('string'),
  addressLine2 : attr('string'),
  city         : attr('string'),
  state        : attr('string'),
  zipCode      : attr('string'),

  config : attr(),
  notes  : attr(),
  miscellaneous: attr(),

  userDairyConfig: belongsTo('user-dairy-config'),

  dairyContacts: hasMany('dairy-contact'),

  locationId     : belongsTo('location'),
  organizationId : belongsTo('organization'),
  userId         : attr('string'), // TODO: Question - Why is this a string?

  primaryContact: computed('dairyContacts.{[],@each.primary}', function() {
    const contacts = this.get('dairyContacts');
    return contacts.filterBy('primary', true).get('firstObject');
  }),

  cityState: computed('locationId.city', function() {
    const city = this.get('locationId.city');
    const state = this.get('locationId.state');
    return isPresent(city) && isPresent(state) ? `${city}, ${state}` : city;
  })
});
