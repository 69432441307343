import Controller from '@ember/controller';
import PeriodMixin from 'agropur-client/mixins/period';
import moment from 'moment';
import CSVExportMixin from 'agropur-client/mixins/csv-export';

export default Controller.extend(PeriodMixin, CSVExportMixin, {

  allMonthsExpanded: false,

  actions: {
    toggleMonth(month){
      month.set('expanded', !month.expanded);
    },
    updateSelectedDairies(dairies){
      this.model.selectedDairies.setObjects(dairies);
      this.model.sortedMonths.forEach(m => {
        m.get('selectedDairies').setObjects(dairies);
      });
    },
    expandAllMonths(months){
      this.set('allMonthsExpanded', true);
      months.forEach(m => {
        m.set('expanded', true);
      })
    },
    collapseAllMonths(months){
      this.set('allMonthsExpanded', false);
      months.forEach(m => {
        m.set('expanded', false);
      })
    },
    csvExport(){
      const data = {
        filename : null,
        rows     : []
      };
      const header = ['Month', 'Producer Number', 'Producer', 'Planned Production', 'Basis Contracted(lbs)', 'Basis Contracted(%)', 'Weighted Average Price', 'Current Offer'];
      data.rows.push(header);

      const month = this.get('model.sortedMonths.firstObject');

      const date = moment.utc(month.get('date')).format('MMM YYYY');

      const agropurBasisPrice = month.get('basisInstrument.price');

      month.get('dairies').forEach((d) => {
        const producerId = d.get('producerId');
        const producer = d.get('name').replace(',', ' ');

        const totalProduction = d.get('plannedProduction');
        const totalAgropurBasisPoundsHedged = d.get('basisPoundsHedged');
        const totalAgropurBasisPercentageHedged = d.get('basisPercentageHedged');
        const totalAgropurBasisHedgePrice = d.get('averageBasisPrice');

        const dairyRow = [date, producerId, producer, totalProduction.toFixed(0), totalAgropurBasisPoundsHedged.toFixed(0), totalAgropurBasisPercentageHedged.toFixed(4), totalAgropurBasisHedgePrice.toFixed(2), agropurBasisPrice.toFixed(2)];
        data.rows.push(dairyRow);
      });

      data.filename = this.get('model.organization.name').split(' ').join('_');
      return this.downloadCSVFile(data, 'Weighted Average Basis Price', this.model.periodDate);

    }
  }

  // init(){
  //   this._super(...arguments);
  //   this.set('selectedDairies', []);
  // },

  // actions: {
  //   expandAllMonths() {
  //     set(this, 'organizationMonthService.allMonthsExpanded', true);
  //     get(this, 'organizationMonthService.orderedFilteredOrganizationMonths').forEach((m) => {
  //       set(m, 'expanded', true);
  //     });
  //   },
  //   collapseAllMonths() {
  //     set(this, 'organizationMonthService.allMonthsExpanded', false);
  //     get(this, 'organizationMonthService.orderedFilteredOrganizationMonths').forEach((m) => {
  //       set(m, 'expanded', false);
  //     });
  //   },
  //   expandAllDairies() {
  //     set(this, 'organizationMonthService.allDairiesExpanded', true);
  //     get(this, 'organizationMonthService.orderedFilteredOrganizationMonths').forEach((m) => {
  //       get(m, 'dairyArray').forEach((d) => {
  //         set(d, 'expanded', true);
  //       });
  //       set(m, 'expanded', true);
  //     });
  //   },
  //   collapseAllDairies() {
  //     set(this, 'organizationMonthService.allDairiesExpanded', false);
  //     get(this, 'organizationMonthService.orderedFilteredOrganizationMonths').forEach((m) => {
  //       get(m, 'dairyArray').forEach((d) => {
  //         set(d, 'expanded', false);
  //       });
  //       set(m, 'expanded', false);
  //     });
  //   },
  //   toggleOne(thing) {
  //     set(thing, 'expanded', !get(thing, 'expanded'));
  //   }
  // }

});
