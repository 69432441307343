/* eslint-disable ember/no-function-prototype-extensions */
import Component from '@ember/component';
import {isNone} from '@ember/utils';

export default Component.extend({
    layoutName: "src/templates/components/rd-display-decimal-price",
    decimalPrice: function(){
      var fmt = this.get("format") === undefined ? "0,0.00" : this.get("format");
      var multiple = this.get("multiple") === undefined ? 1 : this.get("multiple");
      if(isNone(this.get("price")) || isNone(this.get("tickSize")) || isNone(this.get("tickValue"))){
        return null;
      } else {
        // eslint-disable-next-line no-undef
        var dec = new BigNumber(this.get("price")).dividedBy(this.get("tickSize")).times(this.get("tickValue")).times(multiple).toNumber();
        // eslint-disable-next-line no-undef
        return numeral(dec).format(fmt);
      }
    }.property("price","tickSize","tickValue", "format","times")
  });
