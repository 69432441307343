import Mixin from '@ember/object/mixin';
import moment from 'moment';

export default Mixin.create({

      downloadCSVFile(data, report, date) {
        let csvContent = '';
        data.rows.forEach((rowArray) => {
          const row = rowArray.join(',');
          csvContent += `${row}\r\n`;
        });

        report = report.split(' ').join('_');
        const filename = `${data.filename}_${report}_${moment(date).format('MMYYYY')}.csv`;

        var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8'});
        var link = document.createElement('a');
        var csvUrl = URL.createObjectURL(blob);
        link.href = csvUrl;
        link.style = "visibility:hidden";
        link.download = filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return filename;

      },
});
