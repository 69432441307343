import Component from '@ember/component';
import { later } from '@ember/runloop';

export default Component.extend({
  tagName: 'em',

  attributeBindings: [
    'data-toggle',
    'data-placement',
    'title'
  ],

  classNames  : ['vd-tooltip-icon'],
  showTooltip : false,
  showIcon    : false,

  'data-toggle': 'tooltip',

  mouseEnter() {
    const iconWidth = this.$().width() / 2;
    this.set('showTooltip', true);
    later(() => this._updateTooltipPosition(iconWidth));
  },

  _updateTooltipPosition(iconWidth) {
    const el = this.$().find(`#${this.elementId}-tooltip`);
    const position = ((el.width() / 2) * -1) + iconWidth;
    el.css('left', `${position}px`);
    el.css('visibility', 'visible');
  },

  mouseLeave() {
    this.set('showTooltip', false);
  },

  init(...args) {
    this._super(...args);
  }
});
