import DS from 'ember-data';
import {alias} from '@ember/object/computed';

export default DS.Model.extend({

    instrument:     alias("instrument_id"),
    price: DS.attr("number"),
    quantity:  DS.attr("number"),
    side:  DS.attr("string"),
    exchange:  DS.attr("string"),
    timestamp:  DS.attr("date"),
    depth: DS.attr("raw"),
    is_final:  DS.attr("boolean"),
    close_date:  DS.attr("date"),
    delta:  DS.attr("number"),
    gamma:  DS.attr("number"),
    vega:  DS.attr("number"),
    theta:  DS.attr("number"),
    epsilon:  DS.attr("number"),
    zeta:  DS.attr("number"),
    rho:  DS.attr("number"),
    volatility:  DS.attr("number"),
    underlying_price:  DS.attr("number"),
    instrument_id:  DS.attr("string"),
    theo:  DS.attr("string"),
    rho2:  DS.attr("string")
});
