import DS from 'ember-data';

export default DS.Model.extend({
    symbol:            DS.attr("string"),
    category:          DS.attr("string"),
    sub_category:      DS.attr("string"),
    name:              DS.attr("string"),
    time_zone:         DS.attr("string"),
    is_open:           DS.attr("boolean"),
    open_time:         DS.attr("date"),
    close_time:        DS.attr("date"),
    enabled:           DS.attr("boolean"),
    trading_status:    DS.attr("string")
});
