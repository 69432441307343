import DS from 'ember-data';
import { computed } from '@ember/object';
const { Model, attr } = DS;

export default Model.extend({
  externalId   : attr('string'),
  email        : attr('string'),
  firstName    : attr('string'),
  lastName     : attr('string'),
  avatar       : attr('string'),
  locale       : attr('string'),
  canTrade     : attr('boolean'),
  addressLine1 : attr('string'),
  addressLine2 : attr('string'),
  city         : attr('string'),
  state        : attr('string'),
  province     : attr('string'),
  zipCode      : attr('string'),

  permissions: attr(),

  fullName: computed('firstName', 'lastName', function() {
    return `${this.get('firstName')} ${this.get('lastName')}`;
  })

});
