import ENV from 'agropur-client/config/environment';
import $ from 'jquery';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { sort } from '@ember/object/computed';

export default Service.extend({
  store: service(),
  router: service('-routing'),

  organizations: null,

  createOrganization() {
    return this.get('store').createRecord('organization', {
      name          : null,
      vaultType     : null,
      location      : null,
      miscellaneous : {
        defaults: {
          dairy: {
            productionType: 'TOTAL'
          }
        }
      }
    });
  },

  _getAvailableOrganizations() {
    this.set('organizations', this.get('store').findAll('organization'));
  },

  init(){
    this._super(...arguments);
    this.set('organizationSorting',['name']);
  },
  
  availableOrganizations : sort('organizations', 'organizationSorting'),

  _switchOrganization(organization) {
    $.ajax({
      url    : `${ENV.APP.HOST}api/organizations/switch/${organization.id}`,
      method : 'GET'
    }).then(() => {
      later(() => {
        this.get('router').transitionTo('index');
        window.location.reload();
      }, 200);
    });
  },

  actions: {
    switchOrganization(organization) {
      this._switchOrganization(organization);
    }
  }
});
