import Component from '@ember/component';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';

export default Component.extend({
    init() {
        this._super(...arguments);
    },
    formattedResult: computed('value', 'format','perCWT', 'cwt', 'cwtFormat', 'perCows','cows','total', function(){
        let cwtValue;


        let perCWT = this.perCWT == 'cwt';

        if (this.total) {
            cwtValue = this.value / this.cwt;
        } else {
            cwtValue = this.value;
        }

        if (cwtValue) {
            let temp = 0;
            if (perCWT) {
                temp = cwtValue;
            } else if (this.perCows) {
            if (this.cows > 0) {
                temp = (cwtValue * this.cwt) / this.cows;
            }
            } else if (this.cwt) {
                temp = cwtValue * this.cwt;
            }

            if (!Number.isFinite(temp) || Number.isNaN(temp) || !isPresent(temp)) {
                temp = 0;
            }
            // eslint-disable-next-line no-undef
            return  numeral(temp).format(perCWT ? this.cwtFormat : this.format);
        } else {

            // eslint-disable-next-line no-undef
            return numeral(0).format(perCWT ? this.cwtFormat : this.format);
        }

    }),
    warning: computed('perCWT', 'perCows', 'cwt', 'cows', function(){
        if (this.perCWT && this.total && this.cwt === 0) {
            return "Unable to calculate per cwt value. Missing monthly milk production.";
        }

        if (this.perCows && this.cows === 0) {
            return "Unable to calculate per cow value. Missing cows in milk.";
        }

        if (!this.perCWT && !this.perCows && this.cwt === 0) {
            return "Unable to calculate total dollar value. Missing monthly milk production.";
        }

        return;
    })
}).reopenClass({
    positionalParams: ['value', 'format','perCWT', 'cwt', 'cwtFormat', 'perCows','cows','total']
});