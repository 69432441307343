import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default Route.extend(AuthenticatedRouteMixin, {

  model() {

    let {organization, isOrgAdmin} = this.modelFor('authenticated');

    return hash({
      organization,
      dairies: this.get('store').query('dairy', { organization_id: organization.get('id') }),
      contacts: this.get('store').query('dairy-contact', { primary: true }),
      users: this.get('store').findAll('user'),
      isOrgAdmin
    });
  }

});
