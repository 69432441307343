import DS from 'ember-data';
import { computed } from '@ember/object';

const { attr, belongsTo } = DS;

export default DS.Model.extend({
  title              : attr('string'),
  firstName          : attr('string'),
  lastName           : attr('string'),
  email              : attr('string'),
  phone              : attr('string'),
  primary            : attr('boolean'),
  isAuthorizedTrader : attr('boolean'),
  miscellaneous      : attr(),

  dairyId: belongsTo('dairy'),

  fullName: computed('firstName', 'lastName', function() {
    return `${this.get('firstName')} ${this.get('lastName')}`;
  })
});
