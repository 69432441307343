import DS from 'ember-data';
import moment from 'moment';
import DateUtils from 'agropur-client/utils/date-utils';
import { computed } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';


const { attr, hasMany } = DS;

const { currentRollbackMonth } = DateUtils;

export default DS.Model.extend({
  // externalId      : attr('number'),
  name            : attr('string'),
  globexSymbol    : attr('string'),
  clearingSymbol  : attr('string'),
  formatting      : attr('string'),
  instrumentType  : attr('string'),
  exchange        : attr('string'),
  category        : attr('string'),
  subCategory     : attr('string'),
  contractUnit    : attr('string'),
  contractSize    : attr('string'),
  tickValue       : attr('number'),
  tickSize        : attr('number'),
  priceQuotation  : attr('string'),
  listedContracts : attr(),
  tradingHours    : attr(),
  tradingDays     : attr(),

  vaultInstruments: hasMany('vault-instrument'),

  nonExpiredFutures: computed('marketData.futuresMap.[]', 'name', 'globexSymbol', 'exchange', function() {
    const maxDate = currentRollbackMonth().add(12, 'months');
    const map = this.get('marketData.futuresMap');

    const exchangeLimits = {
      CME  : 12,
      CBOT : 7
    };
    let resp = null;
    if (isPresent(map)) {
      resp = Object
        .keys(map)
        .map(key => map[key][this.get('globexSymbol')])
        .filter(el => el && el.get('shouldDisplay') && el.getExpirationDate)
        .filter(el => el.getExpirationDate().isSameOrBefore(maxDate, 'month'))
        .filter((el, idx) => (idx < exchangeLimits[this.get('exchange')] ? el : null));
    }
    return resp;
  }).readOnly(),

  attributes: computed('globexSymbol', 'isAgropur', function() {
    let productName = null;
    let className = null;
    let title = null;
    let hedgeType = null;
    let liteTitle = null;
    let isFeed = false;
    let isBasis = false;

    switch (this.get('globexSymbol')) {
      case "BASIS":
        productName = 'agropurBasis';
        className = 'ClassThreeMilk';
        title = 'Basis';
        hedgeType = 'PercentageHedged';
        isBasis = true;
        break;
      case "DC":
        productName = 'classThreeMilk';
        className = 'ClassThreeMilk';
        title = 'Class Three + Cheese + Whey';
        hedgeType = this.get('isAgropur') ? 'PercentageHedged' : 'PercentageHedgedWithClassOne';
        break;
      case "GDK":
        productName = 'classFourMilk';
        className = 'ClassFourMilk';
        title = 'Class Four + Butter + NFDM';
        liteTitle = 'Class Four';
        hedgeType = 'PercentageHedgedWithClassOne';
        break;
      case "DY":
        productName = 'dryWhey';
        className = 'ClassThreeMilk';
        title = 'Whey + Whey from Class Three';
        hedgeType = 'PercentageHedgedWithClassOne';
        break;
      case "CSC":
        productName = 'cashSettledCheese';
        className = 'ClassThreeMilk';
        title = 'Cheese + Cheese from Class Three';
        hedgeType = 'PercentageHedgedWithClassOne';
        break;
      case "GNF":
        productName = 'nonfatDryMilk';
        className = 'ClassFourMilk';
        title = 'NFDM + NFDM from Class Four';
        hedgeType = 'PercentageHedgedWithClassOne';
        break;
      case "CB":
        productName = 'cashSettledButter';
        className = 'ClassFourMilk';
        title = 'Butter + Butter from Class Four';
        hedgeType = 'PercentageHedgedWithClassOne';
        break;
      case "ZC":
        productName = 'corn';
        isFeed = true;
        hedgeType = 'PercentageHedged';
        break;
      case "ZM":
        productName = 'soybeanMeal';
        isFeed = true;
        hedgeType = 'PercentageHedged';
        break;
    }
    return { productName, className, title, hedgeType, isFeed, liteTitle, isBasis };
  }),

  getFirstAvailableFutureId(dt) {
    const symbol = this.get('globexSymbol');
    const listed = this.get('listedContracts');
    const date   = moment(dt);
    let month    = date.get('month');
    let futuresMonthCode = ['F', 'G', 'H', 'J', 'K', 'M', 'N', 'Q', 'U', 'V', 'X', 'Z'][month];
    if (isEmpty(listed)) {
      return null;
    }

    if (listed.indexOf(futuresMonthCode) < 0) {
      let iterationsCount = 0;
      do {
        date.add(1, 'months');
        month = date.get('month');
        futuresMonthCode = ['F', 'G', 'H', 'J', 'K', 'M', 'N', 'Q', 'U', 'V', 'X', 'Z'][month];
        iterationsCount += 1;
      } while (listed.indexOf(futuresMonthCode) < 0 && iterationsCount <= 12);
    }
    const suffix = date.format("YYMM");
    return `${suffix}${symbol}F`;
  }
});
