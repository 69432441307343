import DS from 'ember-data';

import { computed } from '@ember/object';
const { attr, belongsTo, Model } = DS;

export default Model.extend({
  userId  : belongsTo('user', { async: true }),
  dairyId : belongsTo('dairy', { async: true }),

  clickwrapAccepted   : attr('boolean', { defaultValue: false }),
  clickwrapAcceptedAt : attr('utcdatetime'),

  deleted: attr('boolean'),

  config: attr(),

  permissions: attr(),

  rssFeeds: computed('config.rssFeeds.@each', function() {
    // Returns the list of id's of active RSS feeds
    return this.get('config.rssFeeds') || [2];
  }),

  historicalPercentileConfig: computed('config.{histPercentile,histPercentile.type,histPercentile.range}', function() {
    return this.get('config.histPercentile') || {
      range : 5,
      type  : 'normal' // Seasonal or normal
    };
  }),

  display: computed('config.displayMode', function() {
    let result = null;
    if (this.get('config.displayMode.perCWT')) {
      result = 'cwt';
    }
    if (this.get('config.displayMode.perCow')) {
      result = 'cow';
    }
    if (this.get('config.displayMode.perTotal')) {
      result = 'total';
    }
    return result;
  })
});
