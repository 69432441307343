import moment from 'moment';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import DisplayModeMixin from 'agropur-client/mixins/display-mode';

export default Component.extend(DisplayModeMixin, {

  store       : service(),
  month       : service(),

  quartersExpanded : false,
  isCombined       : true,
  isHedged         : false,
  isUnhedged       : false,

  classNames: ['vd-quarter-grid'],

  attributeBindings: [
    'isCombined',
    'isHedged',
    'isUnhedged'
  ],

  init(...args) {
    this._super(...args);
  },

  quarters: computed('month.orderedFilteredActiveMonths', function() {
    const list = [];
    // Temp cache
    const quartersMap = {};

    const Quarter = EmberObject.extend({
      months   : A,
      expanded : false,
      _getTotalValue(monthsList, attrName) {
        // TBD: there is a bug here being reported to Sentry... "Cannot read property 'agropurPLAdjustedClassThreePrice' of null"
        return monthsList.reduce((val, month) => (val + month.get(attrName)), 0);
      },
      _getAvgValue(monthsList, attrName) {
        const size = monthsList.length;
        return this._getTotalValue(monthsList, attrName) / size;
      },
      cows: computed('months.@each.cowsInMilk', function() {
        return this._getTotalValue(this.get('months'), 'cowsInMilk');
      }),
      production: computed('months.@each.totalProductionCwt', function() {
        return this._getTotalValue(this.get('months'), 'totalProductionCwt');
      }),
      totalRevenue: computed('months.@each.totalRevenue', function() {
        return this._getTotalValue(this.get('months'), 'totalRevenue');
      }),
      totalMilkCheck: computed('months.@each.totalMilkCheck', function() {
        return this._getTotalValue(this.get('months'), 'totalMilkCheck');
      }),
      totalNonMilkRevenues: computed('months.@each.totalNonMilkRevenues', function() {
        return this._getTotalValue(this.get('months'), 'totalNonMilkRevenues');
      }),
      totalFeedExpenses: computed('months.@each.totalFeedExpenses', function() {
        return this._getTotalValue(this.get('months'), 'totalFeedExpenses');
      }),
      totalNonFeedExpenses: computed('months.@each.totalNonFeedExpenses', function() {
        return this._getTotalValue(this.get('months'), 'totalNonFeedExpenses');
      }),
      totalExpenses: computed('months.@each.totalExpenses', function() {
        return this._getTotalValue(this.get('months'), 'totalExpenses');
      }),
      netIncome: computed('months.@each.netIncome', function() {
        return this._getTotalValue(this.get('months'), 'netIncome');
      }),
      scenarioNetIncome: computed('months.@each.scenarioNetIncome', function() {
        return this._getTotalValue(this.get('months'), 'scenarioNetIncome');
      }),
      milkCheckBreakEven: computed('months.@each.milkCheckBreakEven', function() {
        return this._getTotalValue(this.get('months'), 'milkCheckBreakEven');
      }),
      marginObjective: computed('months.{[],@each.marginObjective}', function() {
        return this._getTotalValue(this.get('months'), 'marginObjective');
      }),
      marginObjectiveExceeded: computed('netIncome', 'marginObjective', function() {
        return this.get('netIncome') > this.get('marginObjective');
      }),
      totalMilkPercentageHedged: computed('months.@each.totalMilkPercentageHedged', function() {
        return this._getAvgValue(this.get('months'), 'totalMilkPercentageHedged');
      }),
      totalMilkPercentageUnhedged: computed('months.@each.totalMilkPercentageUnhedged', function() {
        return this._getAvgValue(this.get('months'), 'totalMilkPercentageUnhedged');
      }),
      unhedgedNetIncome: computed('months.@each.unhedgedNetIncome', function() {
        return this._getTotalValue(this.get('months'), 'unhedgedNetIncome');
      }),
      scenarioUnhedgedNetIncome: computed('months.@each.scenarioUnhedgedNetIncome', function() {
        return this._getTotalValue(this.get('months'), 'scenarioUnhedgedNetIncome');
      }),
      cornPercentageHedged: computed('months.@each.cornPercentageHedged', function() {
        return this._getAvgValue(this.get('months'), 'cornPercentageHedged');
      }),
      soybeanMealPercentageHedged: computed('months.@each.soybeanMealPercentageHedged', function() {
        return this._getAvgValue(this.get('months'), 'soybeanMealPercentageHedged');
      }),
      cornPercentageUnhedged: computed('months.@each.cornPercentageUnhedged', function() {
        return this._getAvgValue(this.get('months'), 'cornPercentageUnhedged');
      }),
      soybeanMealPercentageUnhedged: computed('months.@each.soybeanMealPercentageUnhedged', function() {
        return this._getAvgValue(this.get('months'), 'soybeanMealPercentageUnhedged');
      }),
      totalDairyHedgePL: computed('months.@each.totalDairyHedgePL', function() {
        return this._getTotalValue(this.get('months'), 'totalDairyHedgePL');
      }),
      totalFeedHedgePL: computed('months.@each.totalFeedHedgePL', function() {
        return this._getTotalValue(this.get('months'), 'totalFeedHedgePL');
      }),
      agropurPLAdjustedClassThreePrice: computed('months.@each.agropurPLAdjustedClassThreePrice', function() {
        return this._getAvgValue(this.get('months'), 'agropurPLAdjustedClassThreePrice');
      }),
      agropurPLAdjustedBasisPrice: computed('months.@each.agropurPLAdjustedBasisPrice', function() {
        return this._getAvgValue(this.get('months'), 'agropurPLAdjustedBasisPrice');
      }),
      agropurPLAdjustedForwardPrice: computed('months.@each.agropurPLAdjustedForwardPrice', function() {
        return this._getAvgValue(this.get('months'), 'agropurPLAdjustedForwardPrice');
      }),
      classThreeMilkPercentageHedgedOverTotalProduction: computed('months.@each.classThreeMilkPercentageHedgedOverTotalProduction', function() {
        return this._getAvgValue(this.get('months'), 'classThreeMilkPercentageHedgedOverTotalProduction');
      }),
      offExchangeClassThreeMilkPL: computed('months.@each.offExchangeClassThreeMilkPL', function() {
        return this._getTotalValue(this.get('months'), 'offExchangeClassThreeMilkPL');
      }),
      agropurBasisPercentageHedged: computed('months.@each.agropurBasisPercentageHedged', function() {
        return this._getAvgValue(this.get('months'), 'agropurBasisPercentageHedged');
      }),
      agropurBasisPercentageUnhedged: computed('months.@each.agropurBasisPercentageUnhedged', function() {
        return this._getAvgValue(this.get('months'), 'agropurBasisPercentageUnhedged');
      }),
      classThreeMilkPercentageUnhedged: computed('months.@each.classThreeMilkPercentageUnhedged', function() {
        return this._getAvgValue(this.get('months'), 'classThreeMilkPercentageUnhedged');
      }),
      agropurBasisHedgePrice: computed('months.@each.agropurBasisHedgePrice', function() {
        return this._getAvgValue(this.get('months'), 'agropurBasisHedgePrice');
      }),
      agropurBasisPrice: computed('months.@each.agropurBasisPrice', function() {
        return this._getAvgValue(this.get('months'), 'agropurBasisPrice');
      }),
      classThreeMilkPrice: computed('months.@each.classThreeMilkPrice', function() {
        return this._getAvgValue(this.get('months'), 'classThreeMilkPrice');
      }),
      agropurBasisNetPL: computed('months.@each.agropurBasisNetPL', function() {
        return this._getTotalValue(this.get('months'), 'agropurBasisNetPL');
      })
    });

    this.get('month.orderedFilteredActiveMonths').forEach((m) => {
      const quarter = moment(m.getDate()).utc().format('Q');
      const year    = moment(m.getDate()).utc().format('YYYY');
      const qId     = `${quarter}_${year}`;

      // UNCOMMENT THIS TO ENABLE THE QUARTER TOTALS
      const total = null;

      if (!quartersMap[qId]) {
        quartersMap[qId] = Quarter.create({
          id     : qId,
          quarter,
          year,
          months : [m]
        });
        list.push(quartersMap[qId]);
        if (m.getDate().get('month') % 3 !== 0) {
          // Preload previous 1 / 2 months
          this._addPreviousMonths(m, quartersMap[qId]);
        }
      } else {
        quartersMap[qId].get('months').push(m);
      }
      if (total) {
        list.push(total);
      }
    });
    return list;
  }).readOnly(),

  _addPreviousMonths(month, quarter) {
    month.get('previous').then((prev) => {
      quarter.get('months').unshiftObject(prev);
      if (prev && prev.getDate().get('month') % 3 !== 0) {
        this._addPreviousMonths(prev, quarter);
      }
    });
  },


});
