import DS from 'ember-data';
import { singularize } from 'ember-inflector';
import { typeOf } from '@ember/utils';
import { isArray } from '@ember/array';

// EmbeddedRecordsMixin
const { JSONSerializer } = DS;

export default JSONSerializer.extend({

  pushPayload(store, payload) {

    if (typeOf(payload) === 'object') {
      Object.keys(payload).forEach((key) => {
        const modelName  = singularize(key);
        const modelClass = store.modelFor(modelName);
        const serializer = store.serializerFor(modelName);
        if (serializer !== this && serializer.pushPayload) {
          return serializer.pushPayload(store, payload[key], modelName, modelClass);
        }
        store.push(serializer._normalizeResponse(
          store,
          modelClass,
          payload[key],
          null, // id
          null, // request type
          !isArray(payload[key]) // isSingle
        ));
        return null;
      });
    }
  },

  keyForRelationship(key, relationship) {
    if (relationship === 'belongsTo') {
      return `${key}Id`;
    }
    return this._super(key, relationship);
  }
});
