import DS from 'ember-data';

const { JSONSerializer } = DS;

export default JSONSerializer.extend({
  serialize(...args) {
    const json = this._super(...args);
    // Server expect 'config' attr to be a String
    json.config = JSON.stringify(json.config);
    return json;
  }
});
