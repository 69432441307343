import DS from 'ember-data';
import ENV from 'agropur-client/config/environment';
import DateUtils from 'agropur-client/utils/date-utils';
import { computed } from '@ember/object';
import { isEqual } from '@ember/utils';

const { toUtc } = DateUtils;

const { attr, Model, belongsTo } = DS;
const { currentRollbackMonth } = DateUtils;

export default Model.extend({


  date             : attr('utcdate'),
  cowsInMilk       : attr('number', { defaultValue: 0 }),
  productionType   : computed.alias('dairy.productionType'),
  production       : attr('number', { defaultValue: 0 }),
  actualProduction : attr('number', { defaultValue: 0 }),

  historicalPrice : belongsTo('historical-price', { inverse: null }),
  previous        : belongsTo('month', { inverse: null }),

  dairy: belongsTo('dairy'),

  classThreeMilkInstrument    : null,
  agropurBasisInstrument      : null,

  totalPoundsPerContract: 200000,

  classThreeMilkPercentageHedgedLimit : ENV.APP.HEDGE_LIMITS.classThreeMilk,
  agropurBasisPercentageHedgedLimit   : ENV.APP.HEDGE_LIMITS.agropurBasis,


  classThreeMilkForwardPriceHedged: computed('classThreeMilkForwardPositions', 'classThreeMilkForwardPoundsHedged', function() {
    let price = 0;
    this.classThreeMilkForwardPositions.forEach((p) => {
      price += p.get('price') * (p.get('quantity') / this.classThreeMilkForwardPoundsHedged);
    });
    return price;
  }),

  classThreeMilkPutOptionPriceHedged: computed('classThreeMilkPutOptionPositions', 'classThreeMilkPutOptionPoundsHedged', function() {
    let price = 0;
    this.classThreeMilkPutOptionPositions.forEach((p) => {
      price += (p.get('strike') - p.get('price')) * (p.get('quantity') / this.classThreeMilkPutOptionPoundsHedged);
    });
    return price;
  }),

  displayedClassThreeMilkPutOptionPriceHedged: computed('classThreeMilkPutOptionPositions', 'classThreeMilkPutOptionPoundsHedged', function() {
    let price = 0;
    this.classThreeMilkPutOptionPositions.forEach((p) => {
      price += p.get('strike') * (p.get('quantity') / this.classThreeMilkPutOptionPoundsHedged);
    });
    return price;
  }),

  classThreeMilkCallOptionPriceHedged: computed('classThreeMilkCallOptionPositions', 'classThreeMilkCallOptionPoundsHedged', function() {
    let price = 0;
    this.classThreeMilkCallOptionPositions.forEach((p) => {
      price += (p.get('strike') + p.get('price')) * (p.get('quantity') / this.classThreeMilkCallOptionPoundsHedged);
    });
    return price;
  }),

  classThreeMilkForwardPercentageHedged: computed('classThreeMilkForwardPoundsHedged', 'totalProduction', function() {
    return this.totalProduction > 0 ? this.classThreeMilkForwardPoundsHedged / this.totalProduction : Infinity;
  }),



  classThreeMilkPutOptionPercentageHedged: computed('classThreeMilkPutOptionPoundsHedged', 'totalProduction', function() {
    return this.classThreeMilkPutOptionPoundsHedged / this.totalProduction || 0;
  }),


  classThreeMilkCallOptionPercentageHedged: computed('classThreeMilkCallOptionPoundsHedged', 'totalProduction', function() {
    return this.classThreeMilkCallOptionPoundsHedged / this.totalProduction || 0;
  }),

  classThreePoundsHedged: computed.alias('classThreeMilkPoundsHedged'),

  classThreeMilkPercentageHedged: computed('classThreeMilkPercentageHedgedOverTotalProduction', function() {
    return this.classThreeMilkPercentageHedgedOverTotalProduction;
  }),


  classThreeMilkPercentageUnhedged: computed('classThreeMilkPercentageHedged', function() {
    return Math.max(0, 1 - this.classThreeMilkPercentageHedged);
  }),

  offExchangeClassThreeMilkPL: computed('classThreeMilkForwardPL', 'classThreeOptionPL', function() {
    return this.classThreeMilkForwardPL + this.classThreeOptionPL;
  }),

  classThreeMilkPositions: computed.filterBy('manualPositions', 'product_id', 'DC'),
  classThreeMilkForwardPositions: computed.filterBy('classThreeMilkPositions', 'type', 'FORWARD'),
  classThreeMilkPutOptionPositions: computed.filterBy('classThreeMilkPositions', 'type', 'PUT'),
  classThreeMilkCallOptionPositions: computed.filterBy('classThreeMilkPositions', 'type', 'CALL'),

  classThreeMilkForwardPoundsHedged: computed('classThreeMilkForwardPositions.@each.quantity', function() {

    return this.classThreeMilkForwardPositions.reduce((arr, p) => {
      if(p && p.quantity){
        return arr + parseInt(p.quantity);
      } else {
        return arr;
      }
    }, 0);

  }),

  classThreeMilkForwardCWTHedged: computed('classThreeMilkForwardPoundsHedged', function(){
    return this.classThreeMilkForwardPoundsHedged / 100;
  }),

  classThreeMilkForwardPL: computed('classThreeMilkForwardPositions.@each.plInDollars', function() {
    return this.classThreeMilkForwardPositions.reduce((arr, p) => {
      return arr + p.plInDollars;
    }, 0);
  }),

  classThreeMilkPutOptionPoundsHedged: computed('classThreeMilkPutOptionPositions.@each.quantity', function() {
    return this.classThreeMilkPutOptionPositions.reduce((arr, p) => {
      if(p && p.quantity){
        return arr + parseInt(p.quantity);
      } else {
        return arr;
      }
    }, 0);
  }),

  classThreeMilkPutOptionCWTHedged: computed('classThreeMilkPutOptionPoundsHedged', function(){
    return this.classThreeMilkPutOptionPoundsHedged / 100;
  }),

  classThreePutOptionPL: computed('classThreeMilkPutOptionPositions.@each.plInDollars', function() {
    return this.classThreeMilkPutOptionPositions.reduce((arr, p) => {
      return arr + p.plInDollars;
    }, 0);
  }),

  classThreeMilkCallOptionPoundsHedged: computed('classThreeMilkCallOptionPositions.@each.quantity', function() {
    return this.classThreeMilkCallOptionPositions.reduce((arr, p) => {
      if(p && p.quantity){
        return arr + parseInt(p.quantity);
      } else {
        return arr;
      }
    }, 0);
  }),

  classThreeMilkCallOptionCWTHedged: computed('classThreeMilkCallOptionPoundsHedged', function(){
    this.classThreeMilkCallOptionPoundsHedged / 100;
  }),

  classThreeMilkOptionPoundsHedged: computed('classThreeMilkPutOptionPoundsHedged','classThreeMilkCallOptionPoundsHedged', function(){
    return this.classThreeMilkCallOptionPoundsHedged + this.classThreeMilkPutOptionPoundsHedged;
  }),

  classThreeMilkOptionCWTHedged: computed('classThreeMilkOptionPoundsHedged', function(){
    return this.classThreeMilkOptionPoundsHedged / 100;
  }),

  classThreeCallOptionPL: computed('classThreeMilkCallOptionPositions.@each.plInDollars', function() {
    return this.classThreeMilkCallOptionPositions.reduce((arr, p) => {
      return arr + p.plInDollars;
    }, 0);
  }),

  classThreeOptionPL: computed('classThreePutOptionPL', 'classThreeCallOptionPL', function(){
    return this.classThreeCallOptionPL + this.classThreePutOptionPL;
  }),

  classThreeMilkPoundsHedged: computed('classThreeMilkForwardPoundsHedged', 'classThreeMilkPutOptionPoundsHedged', 'classThreeMilkCallOptionPoundsHedged', function(){
    return this.classThreeMilkForwardPoundsHedged + (this.classThreeMilkPutOptionPoundsHedged > this.classThreeMilkCallOptionPoundsHedged ? this.classThreeMilkPutOptionPoundsHedged : this.classThreeMilkCallOptionPoundsHedged);
  }),

  classThreeMilkCWTHedged: computed('classThreeMilkPoundsHedged', function(){
    return this.classThreeMilkPoundsHedged / 100;
  }),

  agropurForwardPrice: computed('classThreeMilkPrice', 'agropurBasisPrice', function() {
      return this.classThreeMilkPrice + this.agropurBasisPrice;
  }),

  blendedMilkPrice: computed.alias('agropurForwardPrice'),

  basisMilkPositions: computed.filterBy('manualPositions', 'product_id', 'BASIS'),
  basisMilkForwardPositions: computed.filterBy('basisMilkPositions', 'type', 'FORWARD'),

  basisMilkForwardPoundsHedged: computed('basisMilkForwardPositions.@each.quantity', function() {

    return this.basisMilkForwardPositions.reduce((arr, p) => {
      if(p && p.quantity){
        return arr + parseInt(p.quantity);
      } else {
        return arr;
      }

    }, 0);

  }),


  agropurBasisPoundsHedged: computed('basisMilkForwardPoundsHedged', function(){
    return this.basisMilkForwardPoundsHedged;
  }),

  agropurBasisCWTHedged: computed('agropurBasisPoundsHedged', function(){
    return this.agropurBasisPoundsHedged / 100;
  }),

  agropurBasisPercentageHedged: computed('agropurBasisPoundsHedged', 'totalProduction', function() {
    return this.agropurBasisPoundsHedged / this.totalProduction || 0;
  }),

  agropurBasisStaticPoundsHedgeable: computed('agropurBasisPercentageHedgedLimit', 'totalProduction', function(){
    return this.get('agropurBasisPercentageHedgedLimit') * this.get('totalProduction');
  }),

  agropurBasisRemainingPoundsHedgeable: computed('agropurBasisStaticPoundsHedgeable', 'agropurBasisPoundsHedged', function(){
    return this.get('agropurBasisStaticPoundsHedgeable') - this.get('agropurBasisPoundsHedged');
  }),

  agropurClassThreeMilkStaticPoundsHedgeable: computed('classThreeMilkPercentageHedgedLimit', 'totalProduction', function(){
    return this.get('classThreeMilkPercentageHedgedLimit') * this.get('totalProduction');
  }),

  agropurClassThreeMilkRemainingPoundsHedgeable: computed('agropurClassThreeMilkStaticPoundsHedgeable', 'classThreeMilkPoundsHedged', function(){
    return this.get('agropurClassThreeMilkStaticPoundsHedgeable') - this.get('classThreeMilkPoundsHedged');
  }),

  agropurPLAdjustedClassThreePrice: computed('classThreeMilkPrice', 'offExchangeClassThreeMilkPL', 'totalProductionCwt', function() {
    return ((this.classThreeMilkPrice * this.totalProductionCwt) + this.offExchangeClassThreeMilkPL) / this.totalProductionCwt || 0;
  }),

  agropurPLAdjustedBasisPrice: computed('agropurBasisPrice', 'totalProduction', 'agropurBasisPoundsHedged', 'agropurBasisHedgePrice', function() {
    return ((this.agropurBasisPrice * (this.totalProduction - this.agropurBasisPoundsHedged) + (this.agropurBasisHedgePrice * this.agropurBasisPoundsHedged)) / this.totalProduction) || 0;
  }),

  agropurPLAdjustedForwardPrice: computed('agropurPLAdjustedClassThreePrice', 'agropurPLAdjustedBasisPrice', function() {
    return this.classThreeMilkPrice + this.basisPrice;
  }),

  agropurBasisPercentageUnhedged: computed('agropurBasisPercentageHedged', function() {
    return Math.max(0, 1 - this.agropurBasisPercentageHedged);
  }),

  agropurBasisHedgePrice: computed('basisMilkPositions', 'agropurBasisPoundsHedged', function() {
    let price = 0;
    this.basisMilkPositions.forEach((p) => {
      price += p.get('price') * (p.get('quantity') / this.agropurBasisPoundsHedged);
    });
    return price;
  }),

  agropurBasisNetPL: computed('agropurBasisPoundsHedged', 'agropurBasisHedgePrice', 'agropurBasisPrice', function() {
    return this.agropurBasisPoundsHedged > 0 ? (this.agropurBasisHedgePrice - this.agropurBasisPrice) * (this.agropurBasisPoundsHedged / 100) : 0;
  }),

  totalAgropurBasisNetPL: computed.alias('agropurBasisNetPL'),

  classThreeMilkPercentageHedgedOverTotalProduction: computed('classThreeMilkPoundsHedged', 'totalProduction', function() {
    return (this.classThreeMilkPoundsHedged / this.totalProduction) || 0;
  }),


  getPhysicalPositions(globexSymbol) {
    return this.get('physicalPositions')
      .filter(p => isEqual(p.get('product.globexSymbol'), globexSymbol));
  },


  getPhysicalPositionsOptionPL(globexSymbol) {

    let total = 0;
    total += this.getPhysicalPositionsValue(globexSymbol, "CALL", 'plInDollars');
    total += this.getPhysicalPositionsValue(globexSymbol, "PUT", 'plInDollars');
    return total;

  },

  getPhysicalPositionsValue(globexSymbol, type, attrValue) {
    const fSum = (ttl, p) => ttl + p.get(attrValue);
    const filtered = this.getPhysicalPositions(globexSymbol);
    return filtered.filter(p => isEqual(p.get('type').toUpperCase(), type)).reduce(fSum, 0);
  },


  /* ============= MANUAL POSITIONS ============= */
  manualPositions: DS.hasMany('manual-position'),
  physicalPositions: computed.filterBy('manualPositions', 'shouldIncludeInPhysical', true),

  isPast: computed('date', function() {
    return toUtc(this.get('date')).isBefore(currentRollbackMonth(), 'month');
  }),

  isFirstOfTheYear: computed('date', function() {
    // IS JANUARY ?
    return toUtc(this.get('date')).get('month') === 0;
  }),

  daysInMonth: computed('date', function() {
    return toUtc(this.get('date')).daysInMonth();
  }),

  /* ============= MILK PRODUCTION ============= */
  hasValidMilkProduction: computed('productionType', 'cowsInMilk', 'production', function() {
    return this.get('productionType') === 'POUNDS_COW_DAY' ? (this.get('cowsInMilk') > 0) && (this.get('production') > 0) : (this.get('production') > 0);
  }),

  totalProduction: computed.alias('production'),

  totalProductionCwt: computed('totalProduction', function() {
    return this.get('totalProduction') / 100;
  }),



  totalMilkPercentageUnhedged: computed('totalMilkPercentageHedged', function() {
    return Math.max(0, 1 - this.get('totalMilkPercentageHedged'));
  }),

  /* ============= MILKCHECK ============= */

  milkCheck: computed.alias('blendedMilkPrice'),


  totalMilkCheck: computed('milkCheck', 'totalProductionCwt', function() {
    return this.get('milkCheck') * this.get('totalProductionCwt');
  }),


  totalDairyHedgePL: computed('offExchangeClassThreeMilkPL', 'totalAgropurBasisNetPL', function() {
    return this.get('offExchangeClassThreeMilkPL') + this.get('totalAgropurBasisNetPL');
  }),


  totalDairyRevenue: computed('totalMilkCheck', 'totalDairyHedgePL', function() {
    return this.get('totalMilkCheck') + this.get('totalDairyHedgePL');
  }),

  _getInstrumentPrice(instrument, type = 'price') {
    return instrument ? instrument.get(type) : 0;
  },

  /* ============= FORMATTED PRICES ============= */

  classThreeMilkPrice: computed('classThreeMilkInstrument.bestPrice', function() {

    return this.get('historicalPrice.classThree') ? this.get('historicalPrice.classThree') : this.get('classThreeMilkInstrument.bestPrice') / 100;
    //return this._getHistoricalOrInstrumentPrice(...['price', [this.get('isPast'), this.get('classThreeMilkInstrument'), this.get('historicalPrice.classThree'), this.get('classThreeMilkInstrument.price')]]) || 0;
  }),


  agropurBasisPrice: computed('agropurBasisInstrument', 'agropurBasisInstrument.price', function() {
    return this.get('agropurBasisInstrument') ? this.get('agropurBasisInstrument.price') : 0;
  }),

});
