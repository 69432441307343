import DS from 'ember-data';
import ENV from 'agropur-client/config/environment';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';
import {isPresent} from '@ember/utils';

export default DS.JSONAPIAdapter.extend(DataAdapterMixin, {
  host      : ENV.APP.HOST,
  namespace : 'commercial_api/api',

  authorize(xhr) {
    let { auth } = this.get('session.data.authenticated');

    if (isPresent(auth)) {
      xhr.setRequestHeader('X-AUTH-TOKEN', `${auth}`);
    }
  }
});
