import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';
import { A } from '@ember/array';

export default Route.extend(AuthenticatedRouteMixin, {

  model() {
    let {organization, periodDate, isOrgAdmin} = this.modelFor('authenticated');
    let { aggregateData } = this.modelFor('authenticated.organization-aggregate');

    let monthAggregateData = aggregateData.find((m) => {
      return moment(m.date).isSame(periodDate);
    });

    monthAggregateData.get('selectedDairies').clear();
    let selectedDairies = A();

    return hash({
      organization,
      periodDate,
      selectedDairies,
      monthAggregateData,
      isOrgAdmin,
      dairies: this.get('store').query('dairy', { organization_id: organization.get('id') }),
    });
  }
});
