import $ from 'jquery';
import { next } from '@ember/runloop';
import Component from '@ember/component';
import { observer } from '@ember/object';
import { isNone } from '@ember/utils';

export default Component.extend({

  attributeBindings : ['contenteditable', 'draggable', 'style', 'title', 'width'],
  classNameBindings : ['divider:vd-table-divider'],

  isUserTyping    : false,
  isUserCancel    : false,
  draggable       : 'true',
  contenteditable : 'true',
  plaintext       : false,
  object          : null,
  format          : null,
  displayFactor   : 1,
  attribute       : null,
  divider         : false,

  isPercentageValue() {
    return this.get('format').match(/%/g);
  },

  didInsertElement() {
    return this._formatContent();
  },

  // eslint-disable-next-line ember/no-observers
  valueObserver: observer('value', function() {
    if (!this.get('isUserTyping') && this.get('value') !== undefined) {
      return this._formatContent();
    }
    return null;
  }),

  focusIn() {
    let value = this.get('value');

    this.set('isUserCancel', false);

    if (!this.get('plaintext') && !isNone(value)) {
      // If too many significant digits, round
      value = this._roundValue(value);

      // eslint-disable-next-line no-undef
      value = new BigNumber(value).times(this.get('displayFactor'));

      if (this.isPercentageValue()) {
        value = value.times(100);
      }

      value = value.toNumber();
    }

    // Set the care at the end of the cell content
    next(this, () => this._resetCaretPosition(value));

    return this.$().text(value);
  },

  focusOut() {
    this._applyValue();
    this._formatContent();
    this.set('isUserTyping', false);
    document.getSelection().removeAllRanges();
  },

  keyDown(event) {
    if (event.keyCode === 27) { // ESC key
      this.set('isUserCancel', true);
      $(event.currentTarget).blur();
      return false;
    }

    if (event.keyCode === 13) {
      // ENTER KEY IS PRESSED
      $(event.currentTarget).blur();
      // Try to go to the next row, same col
      const idx = $(event.currentTarget).closest('td').index() + 1;
      let el  = $(event.currentTarget);
      if (el.closest('tr').next().find('.grid-year-header').length > 0) {
        el = $(el.closest('tr').next().find('.grid-year-header')[0]);
      }
      el.closest('tr').next().find(`td:nth-child(${idx}).ember-view`).focus();
      return false;
    }

    if (!event.metaKey) {
      return this.set('isUserTyping', true);
    }
    return false;
  },

  keyUp(event) {
    let inputValue = this.$().text();
    let value      = 0;

    if (
      event.keyCode === 13 || // ENTER KEY
      event.keyCode === 39 || // LEFT ARROW
      event.keyCode === 38 || // RIGHT ARROW
      event.keyCode === 37 || // UP ARROW
      event.keyCode === 40) { // DOWN ARROW
      // do nothing
      return false;
    }

    if (!this.get('plaintext')) {
      if (inputValue === '' || inputValue === null) {
        inputValue = '0';
      }

      inputValue = inputValue.replace(/[^\d.-]/g, '');
      inputValue = this._roundValue(inputValue);

      // eslint-disable-next-line no-undef
      value = new BigNumber(inputValue);
      if (this.isPercentageValue()) {
        value = value.dividedBy(100);
      }
      value = value.dividedBy(this.get('displayFactor')).toNumber();
    } else {
      value = inputValue === '' ? ' ' : inputValue;
    }
    return this._setValue(value);
  },

  dragStart(event) {
    this._formatContent();

    const colClass = this._getColClass($(event.target));

    if (colClass) {
      localStorage.setItem('colClass', colClass);
    } else {
      localStorage.removeItem('colClass');
    }

    localStorage.setItem('poorSpec', this.get('value'));
    localStorage.setItem('originId', this.get('object').get('id'));
  },

  dragEnd() {
    localStorage.removeItem('poorSpec');
    localStorage.removeItem('originId');
    localStorage.removeItem('colClass');
  },

  dragEnter(event) {
    event.preventDefault();
    event.stopPropagation();

    const object = this.get('object');

    if (object.get('id') === localStorage.getItem('originId')) {
      return false;
    }

    if (localStorage.getItem('colClass') && localStorage.getItem('colClass') !== this._getColClass(event.target)) {
      return false;
    }
    /* eslint-disable no-param-reassign */
    event.dataTransfer.effectAllowed = 'copyMove';
    /* eslint-enable no-param-reassign */

    $(event.target).addClass('drop-enter');

    if (this.get('plainText')) {
      this._setValue(localStorage.getItem('poorSpec'));
    } else {
      this._setValue(parseFloat(localStorage.getItem('poorSpec')));
    }
    this._applyValue();
    return false;
  },

  _formattedValue() {
    let value = this.get('value');

    if (!this.get('plaintext') && !isNone(value)) {
      // If too many significant digits, round
      value = this._roundValue(value);

      // eslint-disable-next-line no-undef
      value = new BigNumber(value).times(this.get('displayFactor'));

      if (this.get('format')) {
        // eslint-disable-next-line no-undef
        value = numeral(value.toNumber()).format(this.get('format'));
      }
    }
    return value;
  },

  _resetCaretPosition(value) {
    const selection       = document.getSelection();
    const range           = document.createRange();
    const contenteditable = this.$()[0];
    const size            = value.toString().length;
    if (contenteditable.lastChild && contenteditable.lastChild.nodeType === 3) {
      range.setStart(contenteditable.lastChild, size);
    } else {
      range.setStart(contenteditable, size);
    }
    selection.removeAllRanges();
    selection.addRange(range);
  },

  _roundValue(value) {
    // eslint-disable-next-line no-undef
    return numeral(value).format('0.[000000]');
  },

  _getColClass(target) {
    return $(target).closest('td')
          .attr('class')
            .split(' ')
              .find(cls => cls.match(/col-\d*$/));
  },

  dragLeave(event) {
    $(event.target).removeClass('drop-enter');
  },

  _formatContent() {
    return this.$().text(this._formattedValue());
  },

  _setValue(value) {
    if (this.get('attribute')) {
      let object = null;
      if (this.get('object.content')) {
        object = this.get('object.content');
      } else {
        object = this.get('object');
      }
      object.set(this.get('attribute'), value);
    } else {
      this.set('setValue', value);
    }
  },

  _applyValue() {
    let object = null;
    if (this.get('object.content')) {
      object = this.get('object.content');
    } else {
      object = this.get('object');
    }
    const isUserCancel = this.get('isUserCancel');
    if (object) {
      if (isUserCancel && object.rollback) {
        object.rollback();
        // Exit the apply function
        return;
      }

      if (object.validate) {
        object.validate().then(() => {
          if (object.get('isValid') /* && get(object, 'isDirty')*/) {
            object.save();
            if (object.execute) {
              object.execute();
            }
          } else if (object.get('isInvalid')) {
            object.get('errors').forEach(error => this.get('notification').error(error.validation));
            object.rollback();
          }
        });
      } else {
        object.save();
      }
    }
  }

});
