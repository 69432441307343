import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';
import EmberObject from '@ember/object';

export default Route.extend(AuthenticatedRouteMixin, {
    model() {

        const {periodDate, dairy, classThreeFutures, classFourFutures, dryWheyFutures, cashSettledCheeseFutures, nonfatDryMilkFutures, cashSettledButterFutures, soybeanMealFutures, cornFutures, soybeanFutures, wheatFutures} = this.modelFor('authenticated');


        return hash({
            periodDate,
            months: this.get('store').query('month', {start_date: periodDate.format("YYYY-MM-DD"), end_date: moment(periodDate).add(2, 'years').format("YYYY-MM-DD"), dairy_id: dairy.id}),
            classThreeFutures: classThreeFutures.filter((f)=>{
                return moment(f.get('expiration_date')).isSameOrBefore(moment().add(12, 'months'));
            }),
            classFourFutures: classFourFutures.filter((f)=>{
                return moment(f.get('expiration_date')).isSameOrBefore(moment().add(12, 'months'));
            }),
            dryWheyFutures: dryWheyFutures.filter((f)=>{
                return moment(f.get('expiration_date')).isSameOrBefore(moment().add(12, 'months'));
            }),
            cashSettledCheeseFutures: cashSettledCheeseFutures.filter((f)=>{
                return moment(f.get('expiration_date')).isSameOrBefore(moment().add(12, 'months'));
            }),
            nonfatDryMilkFutures: nonfatDryMilkFutures.filter((f)=>{
                return moment(f.get('expiration_date')).isSameOrBefore(moment().add(12, 'months'));
            }),
            cashSettledButterFutures: cashSettledButterFutures.filter((f)=>{
                return moment(f.get('expiration_date')).isSameOrBefore(moment().add(12, 'months'));
            }),
            soybeanMealFutures: soybeanMealFutures.filter((f)=>{
                return moment(f.get('expiration_date')).isSameOrBefore(moment().add(10, 'months'));
            }),
            cornFutures: cornFutures.filter((f)=>{
                return moment(f.get('expiration_date')).isSameOrBefore(moment().add(12, 'months'));
            }),
            soybeanFutures: soybeanFutures.filter((f)=>{
                return moment(f.get('expiration_date')).isSameOrBefore(moment().add(12, 'months'));
            }),
            wheatFutures: wheatFutures.filter((f)=>{
                return moment(f.get('expiration_date')).isSameOrBefore(moment().add(12, 'months'));
            }),
            liveCattleFutures: this.store.query("instrument", {'instrument_type': 'future', 'product_group_symbol': 'LE'}),

            cheeseBarrelSpotPrice: fetch(`https://s3.amazonaws.com/optionshop/market_data/stlags/RB.json`).then(async function(response) {
                let res = await response.json();
                if(res){
                    let r = EmberObject.create(res[0]);
                    r.set('product', EmberObject.create({ name: 'Cheese Barrel', symbol: 'RB', multiplier: 1000, rdFormat: '$0,0.0000', tick_size: 1, tick_value: 1}));
                      if(r.settlement){
                          r.set('settlement', r.settlement / 1000);
                          r.set('sessionPriceChange', parseFloat(r.change/1000));
                        r.set('sessionPriceChangePercentage', r.sessionPriceChange / (parseFloat(r.settlement) - r.sessionPriceChange));
                      }
                      return r;
                }
            }),
              cheeseBlockSpotPrice: fetch(`https://s3.amazonaws.com/optionshop/market_data/stlags/KB.json`).then(async function(response) {
                let res = await response.json();
                if(res){
                    let r = EmberObject.create(res[0]);
                    r.set('product', EmberObject.create({ name: 'Cheese Block', symbol: 'KB', multiplier: 1000, rdFormat: '$0,0.0000', tick_size: 1, tick_value: 1}));
                      if(r.settlement){
                          r.set('settlement', r.settlement / 1000);
                          r.set('sessionPriceChange', parseFloat(r.change/1000));
                        r.set('sessionPriceChangePercentage', r.sessionPriceChange / (parseFloat(r.settlement) - r.sessionPriceChange));
                      }
                      return r;
                }
            }),
              butterSpotPrice: fetch(`https://s3.amazonaws.com/optionshop/market_data/stlags/AA.json`).then(async function(response) {
                let res = await response.json();
                if(res){
                    let r = EmberObject.create(res[0]);
                    r.set('product', EmberObject.create({ name: 'Spot Butter', symbol: 'AA', multiplier: 1000, rdFormat: '$0,0.0000', tick_size: 1, tick_value: 1}));
                      if(r.settlement){
                          r.set('settlement', r.settlement / 1000);
                          r.set('sessionPriceChange', parseFloat(r.change/1000));
                        r.set('sessionPriceChangePercentage', r.sessionPriceChange / (parseFloat(r.settlement) - r.sessionPriceChange));
                      }
                      return r;
                }
            }),
              nonfatSpotPrice: fetch(`https://s3.amazonaws.com/optionshop/market_data/stlags/NM.json`).then(async function(response) {
                  let res = await response.json();
                  if(res){
                      let r = EmberObject.create(res[0]);
                      r.set('product', EmberObject.create({ name: 'Spot Nonfat', symbol: 'NM', multiplier: 10, rdFormat: '$0,0.0000', tick_size: 1, tick_value: 1 }));
                      if(r.settlement){
                        r.set('settlement', r.settlement / 10);
                        r.set('sessionPriceChange', parseFloat(r.change/10));
                        r.set('sessionPriceChangePercentage', r.sessionPriceChange / (parseFloat(r.settlement) - r.sessionPriceChange));
                      }
                      return r;
                  }
              }),
        });

    },
});
