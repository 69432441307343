import moment from 'moment';
import { isPresent } from '@ember/utils';
import { helper } from '@ember/component/helper';

export function formatMonth(param) {
  const [month] = param;
  if (isPresent(month)) {
    return moment.utc(month).format('MMM YYYY');
  }
  return 'N/A';
}

export default helper(formatMonth);
