import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';
import Route from '@ember/routing/route';


export default Route.extend(ApplicationRouteMixin, {
  routeAfterAuthentication: 'authenticated.dashboard',

  actions: {

    invalidateSession() {
      this.get('session').invalidate();
    }

  }
});
