import moment from 'moment';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@ember/component';


export default Component.extend({

  classNames        : ['vd-toolbar', 'pull-right'],
  attributeBindings : ['style'],

  showPrintButton       : false,
  showCwtTotalButtons   : false,
  showYearChangeButtons : true,

  displayYear: computed('month.displayMonth', function() {
    return moment(this.get('month.displayMonth')).format('YYYY');
  }),

  actions: {
    toggleMode(perCWT, perCow, perTotal) {
      const userDairyConfig = this.get('application.userDairyConfig');
      userDairyConfig.set('config.displayMode', { perCWT, perCow, perTotal });
      userDairyConfig.save();
    },

    onPrevious() {
      this.get('month').previousYear();
    },

    onNext() {
      this.get('month').nextYear();
    },

    print() {
      window.print();
    }
  }
});
