import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default Route.extend({
  model(){
    let {dairy, organization, isOrgAdmin} = this.modelFor('authenticated');
    return hash({
      dairy,
      organization,
      isOrgAdmin,
      contacts:  this.get('store').query('dairy-contact', {dairy: dairy.id})
    });
  }

});
