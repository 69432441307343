import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';

export default Route.extend(AuthenticatedRouteMixin, {

  model() {
    let {organization, periodDate} = this.modelFor('authenticated');
    let { aggregateData } = this.modelFor('authenticated.organization-aggregate');

    aggregateData.forEach(m => {
      m.get('selectedDairies').clear();
    });

    let monthTrades = aggregateData.flatMap((m) => {
      return m.trades.filter(t => {
        return moment(t.timestamp).startOf('month').isSame(periodDate);
      });

    });


    const sortedTrades = monthTrades.sort((a,b)=> {

      if(a.timestamp > b.timestamp){
        return 1;
      }

      if(a.timestamp < b.timestamp){
        return -1;
      }

      return 0;

    })


    return hash({
      organization,
      periodDate,
      sortedTrades
    });
  }
});
