import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';

export default Route.extend(AuthenticatedRouteMixin, {

  model(params) {
    const {dairy, classThreeFutures, currentUserId, isOrgAdmin} = this.modelFor('authenticated');
    return hash({
      manualPositions: this.get('store').query('manual-position', {dairy_id: dairy.id, product_id: 'DC'}),
      month: this.get('store').findRecord('month', params.id),
      classThreeFutures,
      currentUserId,
      isOrgAdmin
    });
  },
  afterModel(model){

    let instrument = model.classThreeFutures.find((e)=> {
      return moment(e.get('display_expiration_date')).isSame(model.month.date, 'day');
    });

    model.month.set('classThreeMilkInstrument', instrument);

  },
  actions: {
    refreshModel(){
      this.refresh();
    }
  }
});
