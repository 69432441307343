import DS from 'ember-data';

export default DS.Model.extend({
  title           : DS.attr('string'),
  description     : DS.attr('string'),
  customUrl       : DS.attr('string'),
  publishedAt     : DS.attr('date'),
  thumbnails      : DS.attr(),
  viewCount       : DS.attr('number'),
  subscriberCount : DS.attr('number'),
  videoCount      : DS.attr('number'),
  videos          : DS.hasMany('tv-video')
});
