import DS from 'ember-data';

export default DS.Model.extend({
  title       : DS.attr('string'),
  description : DS.attr('string'),
  publishedAt : DS.attr('date'),
  thumbnails  : DS.attr(),
  channel     : DS.belongsTo('tv-channel'),
  videos      : DS.hasMany('tv-video')
});
