import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';

export default Route.extend(AuthenticatedRouteMixin, {


  beforeModel() {
    // if (!this.get('permissionService')._hasPermission('ORGANIZATION_ADMIN')) {
    //   // this.transitionTo('dashboard');
    // }
  }

});
