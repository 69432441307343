import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';

export default Route.extend(AuthenticatedRouteMixin, {
    model() {

        const {periodDate, dairy, displayMode, classThreeFutures, organization, basisInstruments} = this.modelFor('authenticated');

        return hash({
            periodDate,
            displayMode,
            classThreeFutures,
            basisInstruments,
            organization,
            months: this.get('store').query('month', {start_date: periodDate.format("YYYY-MM-DD"), end_date: moment(periodDate).add(23, 'months').format("YYYY-MM-DD"), dairy_id: dairy.id})
        });
    },
    afterModel(model){
        model.months = model.months.sortBy("date");

        let month;
        let classThreeinstrument;
        let basisInstrument;
        for(let i =0; i < model.months.length; i++){
          month = model.months[i];
          classThreeinstrument = model.classThreeFutures.find((e)=> {
            return moment(e.get('display_expiration_date')).isSame(month.date, 'day');
          });
          month.set('classThreeMilkInstrument', classThreeinstrument);

          basisInstrument = model.basisInstruments.find((e)=> {
            return moment.utc(e.get('expiration')).isSame(month.date, 'month');
          });
          month.set('agropurBasisInstrument', basisInstrument);


        }

    }
});
