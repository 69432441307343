import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default Controller.extend({
  store       : service(),

  actions: {
    saveOrganization(organization) {
      organization.save();
    },

    rollbackOrganization(organization) {
      organization.rollbackAttributes();
    },

    // toggleInfoModal() {
    //   this.get('application')._toggleInfoModal();
    // }
  }

});
