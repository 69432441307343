import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default Route.extend(AuthenticatedRouteMixin, {
    marketDataPolling: service('market-data'),
    model() {

        const {displayMode, dairy, organization, classThreeFutures, classFourFutures, dryWheyFutures, cashSettledCheeseFutures, nonfatDryMilkFutures, cashSettledButterFutures, soybeanMealFutures, cornFutures, soybeanFutures, wheatFutures, basisInstruments} = this.modelFor('authenticated');

        // Show the previous month for the first week of the new month.
        let startDate = moment().date() < 8 ? moment().startOf('month').subtract(1, 'month') : moment().startOf('month');

        return hash({
            displayMode,
            organization,
            months: this.get('store').query('month', {start_date: startDate.format("YYYY-MM-DD"), end_date: moment(startDate).add(23, 'months').format("YYYY-MM-DD"), dairy_id: dairy.id}),
            classThreeFuture:  this.getCurrentFuture(classThreeFutures),
            classFourFuture: this.getCurrentFuture(classFourFutures),
            dryWheyFuture: this.getCurrentFuture(dryWheyFutures),
            cashSettledCheeseFuture: this.getCurrentFuture(cashSettledCheeseFutures),
            nonfatDryMilkFuture: this.getCurrentFuture(nonfatDryMilkFutures),
            cashSettledButterFuture: this.getCurrentFuture(cashSettledButterFutures),
            soybeanMealFuture: this.getCurrentFuture(soybeanMealFutures),
            cornFuture: this.getCurrentFuture(cornFutures),
            soybeanFuture: this.getCurrentFuture(soybeanFutures),
            wheatFuture: this.getCurrentFuture(wheatFutures),
            classThreeFutures,
            basisInstruments
        });

    },
    afterModel(model){
      model.months = model.months.sortBy("date");

      let month;
      let instrument;
      let basisInstrument;
      for(let i =0; i < model.months.length; i++){
        month = model.months[i];
        instrument = model.classThreeFutures.find((e)=> {
          return moment(e.get('display_expiration_date')).isSame(month.date, 'day');
        });
        month.set('classThreeMilkInstrument', instrument);

        basisInstrument = model.basisInstruments.find((e)=> {
          return moment.utc(e.get('expiration')).isSame(month.date, 'month');
        });
        month.set('agropurBasisInstrument', basisInstrument);
      }

      this.get('marketDataPolling').start();

      let marketDataInstruments = [].concat(model.classThreeFutures.get('content'));
      marketDataInstruments.push(model.classFourFuture, model.dryWheyFuture, model.cashSettledCheeseFuture, model.nonfatDryMilkFuture, model.cashSettledButterFuture, model.soybeanMealFuture, model.cornFuture, model.soybeanFuture, model.wheatFuture)

      this.get('marketDataPolling').subscribeTo(marketDataInstruments);

    },
    getCurrentFuture: function(futures){

      let startDate = moment.utc();

      if(startDate.date() > 8){
        startDate.add(1, 'month');
      }

      startDate.startOf('month');

      let sortedFutures = futures.sortBy('display_expiration_date');
      return sortedFutures.find((f) => {
        return moment(f.get('display_expiration_date')).isSame(startDate);
      });
    }
});