import $ from 'jquery';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({

  classNames        : ['modal'],
  classNameBindings : ['fade'],
  attributeBindings : ['tabindex', 'data-backdrop', 'data-keyboard'],
  ariaRole          : 'dialog',
  tabindex          : '-1',
  'data-backdrop'   : 'static',
  'data-keyboard'   : 'false',

  _isOpen: false,

  showModal: computed('_isOpen', {
    get() {
      return this.get('_isOpen');
    },
    set(key, value) {
      if (value) {
        this._show();
      } else {
        this._hide();
      }
      return value;
    }
  }),

  _show() {
    this.set('_isOpen', true);
    $(this.$()).modal('show');
  },

  _hide() {
    this.set('_isOpen', false);
    $(this.$()).modal('hide');
  },

  didInsertElement(...args) {
    this._super(...args);

    const modal = $(this.$()).modal({
      show     : this._isOpen,
      keyboard : false
    });

    modal.on('hidden.bs.modal', () => {
      this.set('_isOpen', false);
    });
  }
});
