import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';
import { A } from '@ember/array';

export default Route.extend(AuthenticatedRouteMixin, {

  model() {
    let {organization, periodDate} = this.modelFor('authenticated');
    let { aggregateData } = this.modelFor('authenticated.organization-aggregate');

    const aggregateMonths = aggregateData.filter((m) => {
      const d = moment(m.date);
      const end = moment(periodDate).add(2, 'years');
      return d.isSameOrAfter(periodDate) && d.isBefore(end);
    });

    aggregateMonths.forEach(m => {
      m.get('selectedDairies').clear();
    });
    let selectedDairies = A();

    const sortedMonths = aggregateMonths.sort((a,b)=> {

      if(a.date > b.date){
        return 1;
      }

      if(a.date < b.date){
        return -1;
      }

      return 0;

    });

    return hash({
      organization,
      periodDate,
      selectedDairies,
      dairies: this.get('store').query('dairy', { organization_id: organization.get('id') }),
      sortedMonths
    });
  }
});
