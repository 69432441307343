import ENV from 'agropur-client/config/environment';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { once } from '@ember/runloop';
import { observer } from '@ember/object';
import $ from 'jquery';


const { agropurJeromePlaylistId, agropurOtherPlaylistId } = ENV.google.youtube;

export default Component.extend({
  tv          : service(),

  classNames: 'hidden-xs',

  title      : null,
  customUrl  : null,
  tvPlayerId : null,
  channelId  : null,
  playlistId : null,
  height     : null,

  channel: computed('tv.channels.[]', 'channelId', function() {
    return this.get('tv.channels').filterBy('id', this.get('channelId')).get('firstObject');
  }),

  playlists: computed('tv.playlists.[]', 'channelId', function() {
    return this.get('tv.playlists').filterBy('channel.id', this.get('channelId'));
  }),

  videos: computed('tv.videos.[]', 'channelId', 'playlistId', function() {
    if (this.get('playlistId')) {
      return this.get('tv.videos').filterBy('playlist.id', this.get('playlistId'));
    }
    return this.get('tv.videos').filter((video) => {
      return video.get('channel.id') === this.get('channelId') && video.get('playlist.id') !== agropurJeromePlaylistId && video.get('playlist.id') !== agropurOtherPlaylistId;
    });
  }),

  video: computed('sortedVideos.[]', function() {
    return this.get('sortedVideos').get('firstObject');
  }),

  // using standard descending sort by the date the video was published
  init(){
    this._super(...arguments);
    this.set('videoSorting', ['publishedAt:desc']);
  },

  sortedVideos : computed.sort('videos', 'videoSorting'),



  // eslint-disable-next-line ember/no-observers
  channelChanged: observer('channel', function() {
    once(this, '_loadVideo');
  }),

  // eslint-disable-next-line ember/no-observers
  videoChanged: observer('video', function() {
    once(this, '_loadVideo');
  }),

  didInsertElement() {
    this._super(...arguments);
    once(this, '_loadVideo');
  },

  _loadVideo() {
    const tvPlayer = $(`#${this.get('tvPlayerId')}`);
    const width = tvPlayer.width();
    if (width === undefined) { return; }

    const height = (width / 16) * 9;
    this.set('height', `${height}px`);

    tvPlayer.html(`<iframe id="player" type="text/html" width='${width}' height='${height}' src='https://www.youtube-nocookie.com/embed/${this.get('video.id')}?rel=0?enablejsapi=1&origin=https://www.youtube.com' frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>`);

  },

  actions: {
    save(channel, playlist, video) {
      if (channel && video) {
        const userDairyConfig = this.get('application.userDairyConfig');
        userDairyConfig.set('config.tvChannel', channel);
        const selectedVideo = video || channel.videos[0];
        userDairyConfig.set('config.tvChannel.video', selectedVideo);
        delete userDairyConfig.get('config').tvChannel.videos;
        userDairyConfig.save();
      }
    }
  }

});
