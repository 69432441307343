import Ember from 'ember';
import DS from 'ember-data';
import EmberObject from '@ember/object';
import DateUtils from 'agropur-client/utils/date-utils';
import moment from 'moment';
import { computed } from '@ember/object';
import { isEqual } from '@ember/utils';
const { toUtc } = DateUtils;
import { alias } from '@ember/object/computed';
import { A } from '@ember/array';


const { Model, attr, belongsTo } = DS;
const { currentRollbackMonth, format } = DateUtils;

export default Model.extend({

  date             : attr('utcdate'),
  updatedAt        : attr('utcdate'),
  cowsInMilk       : attr('number'),
  production       : attr('number'),
  actualProduction : attr('number'),

  expanded: false,

  _sum(...args) {
    return args.reduce((p, n) => n + p, 0);
  },

  getDate() {
    return toUtc(this.get('date'));
  },

  _matrixMarginPrice(price = 0, cornPrice = 0, soybeanMealPrice = 0) {
    return price - (cornPrice * 0.833) - (soybeanMealPrice * 0.0083);
  },

  _getCwtValue(value = 0, totalProductionCwt = 0) {
    let result = 0;
    if (totalProductionCwt > 0) {
      result = value / totalProductionCwt;
    }
    return result;
  },

  classThreeMilkPercentageHedgedLimit : computed.alias('organizationId.miscellaneous.hedgeLimits.classThreeMilk'),
  agropurBasisPercentageHedgedLimit   : computed.alias('organizationId.miscellaneous.hedgeLimits.agropurBasis'),

  dairies: attr(),

  historicalPriceId : belongsTo('historical-price'),
  organizationId    : belongsTo('organization'),

  historicalPrice: alias('historicalPriceId'),

  classThreeMilkInstrument: computed('store', function() {
    return this.get('store').peekRecord('instrument', `${this.get('monthCode')}DCF`);
  }),

  agropurBasisInstruments: computed('store', function() {
    return this.get('store').peekAll('vault-instrument');
  }),

  agropurBasisInstrument: computed('agropurBasisInstruments.@each.price', function() {
    return this.get('agropurBasisInstruments').find(instrument => moment.utc(instrument.get('expiration')).isSame(this.get('date'), 'month'));
  }),


  isPast: computed('date', function() {
    return this.getDate().isBefore(currentRollbackMonth(), 'month');
  }).readOnly(),

  _getHistoricalOrInstrumentPrice(attribute, isPast, instrument, historicalPrice) {
    return isPast ? historicalPrice : (this._getInstrumentPrice(instrument, attribute) || historicalPrice);
  },

  _getInstrumentPrice(instrument, type = 'price') {
    return instrument ? instrument.get(type) : 0;
  },

  classThreeMilkPrice: computed('isPast', 'classThreeMilkInstrument', 'historicalPrice.classThree', 'classThreeMilkInstrument.price', function() {
    return this._getHistoricalOrInstrumentPrice(...['price', [this.get('isPast'), this.get('classThreeMilkInstrument'), this.get('historicalPrice.classThree'), this.get('classThreeMilkInstrument.price')]]) || 0;
  }).readOnly(),


  agropurBasisPrice: computed('agropurBasisInstrument', function() {
    return this._getInstrumentPrice([this.get('agropurBasisInstrument')]) || 0;
  }).readOnly(),

  selectedDairies    : computed.alias('organizationMonthService.selectedDairies'),
  allDairiesExpanded : computed.alias('organizationMonthService.allDairiesExpanded'),

  filteredDairies: computed('dairies.[]', 'selectedDairies.[]', function() {
    const selectedDairies = this.get('selectedDairies') ? this.get('selectedDairies').map(d => d.get('name')) : [];
    const dairies = this.get('dairies');

    if (selectedDairies.length > 0) {
      return this.get('dairies').filter(function(dairy) {
        return selectedDairies.includes(dairy.get('dairyName'));
      }, this);
    }

    return dairies;
  }),

  init(){
    this.set('dairySorting', ['producerId']);
  },

  sortedDairies : computed.sort('filteredDairies', 'dairySorting'),

  dairyArray: computed('sortedDairies', 'classThreeMilkPrice', 'agropurBasisPrice', 'allDairiesExpanded', function() {
    const dairies = this.get('sortedDairies');
    const classThreeMilkPrice = this.get('classThreeMilkPrice');
    const agropurBasisPrice = this.get('agropurBasisPrice');
    const classThreeMilkPercentageHedgedLimit = this.get('classThreeMilkPercentageHedgedLimit');
    const agropurBasisPercentageHedgedLimit = this.get('agropurBasisPercentageHedgedLimit');
    const allDairiesExpanded = this.get('allDairiesExpanded');

    const list = [];
    const dairyMap = {};

    const Dairy = EmberObject.extend({
      production        : Ember.K,
      unsortedPositions : A,
      producerId        : null,

      // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
      positionSorting : ['productId'],
      positions       : computed.sort('unsortedPositions', 'positionSorting'),

      _getTotalValue(arrayList, attrName) {
        return arrayList.reduce((val, item) => (val + item[attrName]), 0);
      },

      totalProduction: computed('production.totalProduction', function() {
        return this.get('production.totalProduction');
      }),

      actualProduction: computed('production.actualProduction', function() {
        return this.get('production.actualProduction');
      }),

      productionDifference: computed('actualProduction', 'totalProduction', function() {
        return this.get('actualProduction') - this.get('totalProduction');
      }),

      productionPercentageDifference: computed('actualProduction', 'totalProduction', function() {
        return (this.get('actualProduction') - this.get('totalProduction')) / this.get('totalProduction');
      }),

      classThreeMilkHedgeableProduction: computed('totalProduction', 'classThreeMilkPercentageHedgedLimit', function() {
        return this.get('totalProduction') * this.get('classThreeMilkPercentageHedgedLimit') || 0;
      }),

      agropurBasisHedgeableProduction: computed('totalProduction', 'agropurBasisPercentageHedgedLimit', function() {
        return this.get('totalProduction') * this.get('agropurBasisPercentageHedgedLimit') || 0;
      }),

      hedgeableProduction: computed('classThreeMilkHedgeableProduction', 'agropurBasisHedgeableProduction', function() {
        const classThree = this.get('classThreeMilkHedgeableProduction');
        const basis = this.get('agropurBasisHedgeableProduction');
        return classThree > basis ? classThree : basis;
      }),

      classThreeMilkPositions: computed('positions.[]', function() {
        return this.get('positions').filterBy('productId', 'DC');
      }),

      _getClassThreeMilkPoundsHedged(classThreeMilkPositions) {
        const fSum     = (ttl, p) => ttl + Math.abs(p.get('quantity'));

        const fwTotal   = classThreeMilkPositions.filter(p => isEqual(p.get('type'), "FORWARD")).reduce(fSum, 0);
        const putTotal  = classThreeMilkPositions.filter(p => isEqual(p.get('type'), "PUT")).reduce(fSum, 0);
        const callTotal = classThreeMilkPositions.filter(p => isEqual(p.get('type'), "CALL")).reduce(fSum, 0);

        return fwTotal + (putTotal > callTotal ? putTotal : callTotal);
      },

      totalClassThreeMilkPoundsHedged: computed('positions.@each.quantity', function() {
        return this._getClassThreeMilkPoundsHedged(this.get('classThreeMilkPositions'));
      }),

      totalClassThreeMilkPercentageHedged: computed('totalClassThreeMilkPoundsHedged', 'totalProduction', function() {
        return this.get('totalClassThreeMilkPoundsHedged') / this.get('totalProduction');
      }),

      totalClassThreeMilkNetHedgeAdjustment: computed('classThreeMilkPositions.@each.netHedgeAdjustment', function() {
        return this._getTotalValue(this.get('classThreeMilkPositions'), 'netHedgeAdjustment');
      }),

      agropurBasisPositions: computed('positions.[]', function() {
        return this.get('positions').filterBy('productId', 'BASIS');
      }),

      totalAgropurBasisPoundsHedged: computed('positions.@each.quantity', function() {
        return this._getTotalValue(this.get('agropurBasisPositions'), 'quantity');
      }),

      totalAgropurBasisPercentageHedged: computed('totalAgropurBasisPoundsHedged', 'totalProduction', function() {
        return this.get('totalAgropurBasisPoundsHedged') / this.get('totalProduction');
      }),

      totalAgropurBasisNetHedgeAdjustment: computed('agropurBasisPositions.@each.netHedgeAdjustment', function() {
        return this._getTotalValue(this.get('agropurBasisPositions'), 'netHedgeAdjustment');
      }),

      totalCombinedPoundsHedged: computed('totalClassThreeMilkPoundsHedged', 'totalAgropurBasisPoundsHedged', function() {
        return this.get('totalClassThreeMilkPoundsHedged') + this.get('totalAgropurBasisPoundsHedged');
      }),

      totalCombinedPercentageHedged: computed('totalCombinedPoundsHedged', 'totalProduction', function() {
        return this.get('totalCombinedPoundsHedged') / this.get('totalProduction');
      }),

      totalNetHedgeAdjustment: computed('positions.@each.netHedgeAdjustment', function() {
        return this._getTotalValue(this.get('positions'), 'netHedgeAdjustment');
      }),

      totalCombinedNetHedgeAdjustment: computed('positions.@each.netHedgeAdjustment', function() {
        return this._getTotalValue(this.get('positions'), 'netHedgeAdjustment');
      }),

      totalAgropurBasisHedgePrice: computed('agropurBasisPositions', 'totalAgropurBasisPoundsHedged', 'totalProduction', 'agropurBasisPrice', function() {
        let price = 0;
        this.get('agropurBasisPositions').forEach((position) => {
          const weight = position.get('quantity') / this.get('totalAgropurBasisPoundsHedged');
          price += position.get('hedgePrice') * weight;
        });
        return price;
      }),

      totalAgropurCombinedBasisPrice: computed('agropurBasisPositions', 'totalAgropurBasisPoundsHedged', 'totalProduction', 'agropurBasisPrice', function() {
        let price = 0;
        this.get('agropurBasisPositions').forEach((position) => {
          const weight = position.get('quantity') / this.get('totalAgropurBasisPoundsHedged');
          price += position.get('hedgePrice') * weight;
        });
        return ((price * this.get('totalAgropurBasisPoundsHedged') + (this.get('totalProduction') - this.get('totalAgropurBasisPoundsHedged')) * this.get('agropurBasisPrice')) / this.get('totalProduction')) || this.get('agropurBasisPrice');
      })
    });

    const Position = EmberObject.extend({
      _getProductPrice() {
        return this.get('productId') === 'DC' ? this.get('classThreeMilkPrice') : this.get('agropurBasisPrice');
      },

      _getTotalValue(arrayList, attrName) {
        return arrayList.reduce((val, item) => (val + item[attrName]), 0);
      },

      productPrice: computed('classThreeMilkPrice', 'agropurBasisPrice', function() {
        return this._getProductPrice();
      }),

      hedgePL: computed('productId', 'hedgePrice', 'productPrice', 'plInDollars', function() {
        if (this.get('productId') === 'BASIS') {
          return this.get('hedgePrice') - this.get('productPrice');
        }
        return this.get('plInDollars');
      }),

      netHedgeAdjustment: computed('hedgePL', 'quantity', function() {
        return this.get('hedgePL') * (this.get('quantity') / 100);
      }),


      plInDollars: computed('quantity','isLong','hedgePrice','type','strike','productId','productPrice','agropurBasisPlInDollars', function() {
        let result   = 0;

        if (this.get('type') === "FORWARD") {
          result = (this.get('hedgePrice') - this.get('productPrice')) * (this.get('isLong') ? -1 : 1);
        } else if (this.get('type') === "PUT" || this.get('type') === "CALL") {
          const strikePriceDiff = this.get('strike') - this.get('productPrice');
          if (this.get('type') === "PUT" && strikePriceDiff > 0) {
            result += strikePriceDiff * (this.get('isLong') ? 1 : -1);
          } else if (this.get('type') === "CALL" && strikePriceDiff < 0) {
            result += strikePriceDiff * (this.get('isLong') ? -1 : 1);
          }

          const _price = this.get('hedgePrice');

          if (this.get('isLong')) {
            result -= _price;
          } else {
            result += _price;
          }
        }

        return result;
      })
    });

    dairies.forEach((d) => {
      const positions = d.get('positions');
      const positionList = [];
      const positionMap = {};

      positions.forEach((p) => {
        positionMap[p.id] = Position.create({
          quantity     : p.quantity,
          timestamp    : p.timestamp,
          strike       : p.strike,
          // price        : p.price,
          tradePrice   : p.price,
          hedgePrice   : p.price,
          isLong       : p.long,
          id           : p.id,
          monthId      : p.monthId,
          productId    : p.productId,
          userId       : p.userId,
          positionType : p.positionType,
          type         : p.type,
          deleted      : p.deleted,

          classThreeMilkPrice,
          agropurBasisPrice,

          classThreeMilkPercentageHedgedLimit,
          agropurBasisPercentageHedgedLimit
        });
        positionList.pushObject(positionMap[p.id]);
      });

      dairyMap[d.producerId] = Dairy.create({
        // id         : `${this.get('organizationId.id')}|${d.dairyId}`,
        id                : d.producerId,
        producerId        : parseInt(d.producerId, 10),
        name              : d.dairyName,
        production        : d.production,
        // positions         : positionList,
        unsortedPositions : positionList,
        expanded          : allDairiesExpanded,
        classThreeMilkPrice,
        agropurBasisPrice
      });

      list.pushObject(dairyMap[d.producerId]);
    });
    return list;
  }),


  totalPlannedProduction: computed('dairyArray', function() {
    return this.get('dairyArray').reduce((total, dairy) => total + dairy.get('totalProduction'), 0);
  }),

  totalActualProduction: computed('dairyArray', function() {
    return this.get('dairyArray').reduce((total, dairy) => total + dairy.get('actualProduction'), 0);
  }),

  totalProductionDifference: computed('totalActualProduction', 'totalPlannedProduction', function() {
    return this.get('totalActualProduction') - this.get('totalPlannedProduction');
  }),

  totalProductionPercentageDifference: computed('totalActualProduction', 'totalPlannedProduction', function() {
    return (this.get('totalActualProduction') - this.get('totalPlannedProduction')) / this.get('totalPlannedProduction');
  }),

  _getTotalValue(arrayList, attrName) {
    return arrayList.reduce((val, item) => (val + item[attrName]), 0);
  },

  aggregateHedgeableProduction: computed('totalPlannedProduction', function() {
    return this.get('totalPlannedProduction') * 0.80;
  }),

  aggregateClassThreeMilkPoundsHedgedable: computed('totalPlannedProduction', 'classThreeMilkPercentageHedgedLimit', function() {
    return this.get('totalPlannedProduction') * this.get('classThreeMilkPercentageHedgedLimit');
  }),

  aggregateClassThreeMilkPoundsHedged: computed('dairyArray.@each.totalClassThreeMilkPoundsHedged', function() {
    return this._getTotalValue(this.get('dairyArray'), 'totalClassThreeMilkPoundsHedged');
  }),

  aggregateClassThreeMilkPercentageHedged: computed('aggregateClassThreeMilkPoundsHedged', 'totalPlannedProduction', function() {
    return this.get('aggregateClassThreeMilkPoundsHedged') / this.get('totalPlannedProduction');
  }),

  aggregateClassThreeMilkNetHedgeAdjustment: computed('dairyArray.@each.totalClassThreeMilkNetHedgeAdjustment', function() {
    return this._getTotalValue(this.get('dairyArray'), 'totalClassThreeMilkNetHedgeAdjustment');
  }),

  aggregateAgropurBasisPoundsHedgedable: computed('totalPlannedProduction', 'agropurBasisPercentageHedgedLimit', function() {
    return this.get('totalPlannedProduction') * this.get('agropurBasisPercentageHedgedLimit');
  }),

  aggregateAgropurBasisPoundsHedged: computed('dairyArray.@each.totalAgropurBasisPoundsHedged', function() {
    return this._getTotalValue(this.get('dairyArray'), 'totalAgropurBasisPoundsHedged');
  }),

  aggregateAgropurBasisPercentageHedged: computed('aggregateAgropurBasisPoundsHedged', 'totalPlannedProduction', function() {
    return this.get('aggregateAgropurBasisPoundsHedged') / this.get('totalPlannedProduction') || 0;
  }),

  aggregateAgropurBasisNetHedgeAdjustment: computed('dairyArray.@each.totalAgropurBasisNetHedgeAdjustment', function() {
    return this._getTotalValue(this.get('dairyArray'), 'totalAgropurBasisNetHedgeAdjustment');
  }),

  aggregateCombinedPoundsHedged: computed('aggregateClassThreeMilkPoundsHedged', 'aggregateAgropurBasisPoundsHedged', function() {
    return this.get('aggregateClassThreeMilkPoundsHedged') + this.get('aggregateAgropurBasisPoundsHedged');
  }),

  aggregateCombinedPercentageHedged: computed('aggregateCombinedPoundsHedged', 'totalPlannedProduction', function() {
    return this.get('aggregateCombinedPoundsHedged') / this.get('totalPlannedProduction');
  }),

  aggregateAgropurBasisHedgePrice: computed('dairyArray', 'aggregateAgropurBasisPoundsHedged', function() {
    let price = 0;
    this.get('dairyArray').forEach((dairy) => {
      const weight = dairy.get('totalAgropurBasisPoundsHedged') / this.get('aggregateAgropurBasisPoundsHedged');
      price += dairy.get('totalAgropurBasisHedgePrice') * weight;
    });
    return price || 0;
  }),

  aggregateAgropurCombinedBasisPrice: computed('dairyArray', 'aggregateAgropurBasisPoundsHedged', 'totalPlannedProduction', 'agropurBasisPrice', function() {
    let price = 0;
    this.get('dairyArray').forEach((dairy) => {
      const weight = dairy.get('totalAgropurBasisPoundsHedged') / this.get('aggregateAgropurBasisPoundsHedged');
      price += dairy.get('totalAgropurBasisHedgePrice') * weight;
    });
    return ((price * this.get('aggregateAgropurBasisPoundsHedged') + (this.get('totalPlannedProduction') - this.get('aggregateAgropurBasisPoundsHedged')) * this.get('agropurBasisPrice')) / this.get('totalPlannedProduction')) || this.get('agropurBasisPrice');
  }),

  aggregateCombinedNetHedgeAdjustment: computed('dairyArray.@each.totalCombinedNetHedgeAdjustment', function() {
    return this._getTotalValue(this.get('dairyArray'), 'totalCombinedNetHedgeAdjustment');
  }),

  /* ============= OTHER ============= */
  monthCode: computed('date', function() {
    return format(this.getDate(), "YYMM");
  }).readOnly()
});
