import Controller from '@ember/controller';
import { computed } from '@ember/object';

export default Controller.extend({

  queryParams: ['sortProperties', 'sortAscending', 'searchAttr', 'searchTerm'],

  init(){
    this._super(...arguments);
    this.set('searchAttrs', ['title', 'name', 'phone', 'email']);
  },

  searchAttr  : 'title',
  searchTerm  : '',

  // _getTestString(contact, searchAttr) {
  //   let testString = '';

  //   switch (searchAttr) {
  //     case 'title':
  //       testString = contact.get('title');
  //       break;
  //     case 'name':
  //       testString = contact.get('fullName');
  //       break;
  //     case 'phone':
  //       testString = contact.get('phone');
  //       break;
  //     case 'email':
  //       testString = contact.get('email');
  //       break;
  //   }

  //   return testString || '';
  // },

  filteredDairyContacts: computed('model.{dairy,contacts.[]}', 'searchAttr', 'searchTerm',  function() {
    const searchAttr = this.get('searchAttr');
    const searchTerm = this.get('searchTerm');

    return this.get('model.contacts').filter(function(contact) {
      const testString = searchTerm.length > 0 ? this._getTestString(contact, searchAttr, searchTerm) : '';
      return testString.toLowerCase().includes(searchTerm.toLowerCase());
    }, this);
  }),

  sortProperties: 'title',

  sortProperty: computed('sortProperties', function() {
    return [this._getSortProperty(this.get('sortProperties'))];
  }),

  _getSortProperty(sortProperties) {
    let property = '';

    switch (sortProperties) {
      case 'title':
        property = 'title';
        break;
      case 'name':
        property = 'fullName';
        break;
      case 'phone':
        property = 'phone';
        break;
      case 'email':
        property = 'email';
        break;
    }

    return property || '';
  },

  sortAscending: true,

  dairyContactSorting: computed('sortProperty', 'sortAscending', function() {
    let sortProperty = this.get('sortProperty');
    sortProperty = typeof sortProperty === 'string' ? new Array(sortProperty) : sortProperty;
    const sort = this.get('sortAscending') ? sortProperty[0] : sortProperty[0].concat(':desc');
    return new Array(sort);
  }),

  sortedDairyContacts: computed.sort('filteredDairyContacts', 'dairyContactSorting'),

  actions: {
    toggleCreateModal(record) {
      if (!this.get('showCreateModal')) {
        this.toggleProperty('showCreateModal');

        if (record === 'new') {
          const store = this.get('store');
          record = store.createRecord('dairy-contact', { // eslint-disable-line no-param-reassign
            title         : '',
            firstName     : '',
            lastName      : '',
            phone         : '',
            email         : '',
            primary       : false,
            miscellaneous : {
              notes: ''
            },
            isAuthorizedTrader : false,
            dairyId            : this.get('model.dairy')
          });
        }
        this.set('newContactModel', record);
      }
    },

    saveContact(contact) {
      contact.save().then(() => {
        this.get('model.contacts').update();
      });
      this.toggleProperty('showCreateModal');
    },

    rollbackContact(contact) {
      contact.rollbackAttributes();
      this.toggleProperty('showCreateModal');
    },

    deleteContact(contact) {
      contact.destroyRecord().then(() => {
        this.get('model.contacts').update();
      });
    },

    primaryClicked(contact) {
      contact.toggleProperty('primary');
    }
  }

});
