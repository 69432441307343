import DS from 'ember-data';

export default DS.Transform.extend({
  deserialize: function(serialized) {
    return new Date(serialized);
  },
  serialize: function(deserialized) {
    return deserialized.toISOString().substr(0, 10);
  }
});
