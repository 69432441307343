/* eslint-disable ember/no-function-prototype-extensions */
import DS from 'ember-data';
import {alias} from '@ember/object/computed';

export default DS.Model.extend({
    group_id:    DS.belongsTo("productgroup"),
    productgroup: alias("group_id"),
    parent_symbol:  DS.attr("string"),
    instrument_type:  DS.attr("string"),
    clearing_symbol:  DS.attr("string"),
    display_factor:    DS.attr("number"),
    tick_size:         DS.attr("number"),
    tick_value:        DS.attr("number"),
    tick_boundaries:  DS.attr("raw"),
    tick_sizes:        DS.attr("raw"),
    base_factor:      DS.attr("number"),
    session_start_times:  DS.attr("raw"),
    session_end_times:    DS.attr("raw"),
    settlement_type:  DS.attr("string"),
    exercise_procedure:  DS.attr("string"),
    expiration_type: DS.attr("string"),
    is_weekly:  DS.attr("boolean"),
    name: DS.attr("string"),
    rdFormat: function(){
        var fmt = "0,0.00";
        switch (this.get("parent_symbol")) {
            case "DC":
                fmt = "0,0.00";
                break;
            case "GDK":
                fmt = "0,0.00";
                break;
            case "ZC":
                fmt = "0,0.0000";
                break;
            case "OZC":
                fmt = "0,0.0000";
                break;
            case "CSC":
                fmt = "0,0.0000";
                break;
            case "KE":
                fmt = "0,0.0000";
                break;
            case "OKE":
                fmt = "0,0.0000";
                break;
            case "LE":
                fmt = "0,0.0000";
                break;
            case "ZL":
                fmt = "0,0.00";
                break;
            case "OZL":
                fmt = "0,0.00";
                break;
            case "ZM":
                fmt = "0,0.0";
                break;
            case "OZM":
                fmt = "0,0.0";
                break;
            case "CB":
                fmt = "0,0.0000";
                break;
            case "GNF":
                fmt = "0,0.0000";
                break;
            case "ZS":
                fmt = "0,0.0000";
                break;
            case "OZS":
                fmt = "0,0.0000";
                break;
            case "ZW":
                fmt = "0,0.0000";
                break;
            case "DY":
                fmt = "0,0.0000";
                break;
        }
        return fmt;
      }.property("parent_symbol"),

      rdMultiple: function(){
        var multiple = "1";
        switch (this.get("parent_symbol")) {
            case "DC":
                multiple = "0.0005";
                break;
            case "GDK":
                multiple = "0.0005";
                break;
            case "ZC":
                multiple = "0.0002";
                break;
            case "OZC":
                multiple = "0.0002";
                break;
            case "CSC":
                multiple = "0.00005";
                break;
            case "KE":
                multiple = "0.0002";
                break;
            case "OKE":
                multiple = "0.0002";
                break;
            case "LE":
                multiple = "0.000025";
                break;
            case "ZL":
                multiple = "0.00166666667";
                break;
            case "OZL":
                multiple = "0.00166666667";
                break;
            case "ZM":
                multiple = "0.01";
                break;
            case "OZM":
                multiple = "0.01";
                break;
            case "CB":
                multiple = "0.00005";
                break;
            case "GNF":
                multiple = "0.000022727273";
                break;
            case "ZS":
                multiple = "0.0002";
                break;
            case "OZS":
                multiple = "0.0002";
                break;
            case "ZW":
                multiple = "0.0002";
                break;
            case "DY":
                multiple = "0.000022727273";
                break;
        }
        return multiple;
      }.property("parent_symbol"),

      unitsPerContract: function(){
        var units = "1";
        switch (this.get("parent_symbol")) {
            case "DC":
                units = "200000";
                break;
            case "GDK":
                units = "200000";
                break;
            case "OZC":
                units = "5000";
                break;
            case "ZC":
                units = "5000";
                break;
            case "CSC":
                units = "20000";
                break;
            case "KE":
                units = "5000";
                break;
            case "OKE":
                units = "5000";
                break;
            case "LE":
                units = "40000";
                break;
            case "ZL":
                units = "60000";
                break;
            case "OZL":
                units = "60000";
                break;
            case "ZM":
                units = "100";
                break;
            case "OZM":
                units = "100";
                break;
            case "CB":
                units = "20000";
                break;
            case "GNF":
                units = "44000";
                break;
            case "ZS":
                units = "5000";
                break;
            case "OZS":
                units = "5000";
                break;
            case "ZW":
                units = "5000";
                break;
            case "DY":
                units = "44000";
                break;
        }
        return units;
      }.property("parent_symbol"),
});
