import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  actions: {
      changeDisplayMode(mode) {
          this.changeDisplayMode(mode);
      },
  },
  isPerCWT: computed('displayMode', function(){
      return this.displayMode == 'cwt';
  }),
  isTotal: computed('displayMode', function(){
    return this.displayMode == 'total';
  })
});
