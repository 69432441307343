import { computed } from '@ember/object';
import DS from 'ember-data';

import { toUtc } from 'agropur-client/utils/date-utils';
import { alias } from '@ember/object/computed';



const { attr, Model } = DS;

export default Model.extend({
  date              : attr('string'),
  classThree        : attr('number', { defaultValue: 0 }),
  classThreeMilk    : alias('classThree'),
  classFour         : attr('number', { defaultValue: 0 }),
  classFourMilk     : alias('classFour'),
  corn              : attr('number', { defaultValue: 0 }),
  soybeanMeal       : attr('number', { defaultValue: 0 }),
  nonfatDryMilk     : attr('number', { defaultValue: 0 }),
  dryWhey           : attr('number', { defaultValue: 0 }),
  cashSettledCheese : attr('number', { defaultValue: 0 }),
  cashSettledButter : attr('number', { defaultValue: 0 }),

  classThreeMargin: computed('classThree', 'corn', 'soybeanMeal', function() {
    return this._matrixMarginPrice(this.get('classThree'), this.get('corn'), this.get('soybeanMeal'));
  }),


  classFourMargin: computed('classFour', 'corn', 'soybeanMeal', function() {
    return this._matrixMarginPrice(this.get('classFour'), this.get('corn'), this.get('soybeanMeal'));
  }),

  _matrixMarginPrice(price = 0, cornPrice = 0, soybeanMealPrice = 0) {
    return price - (cornPrice * 0.833) - (soybeanMealPrice * 0.0083);
  },

  isFirstOfTheYear: computed('date', function() {
    // IS JANUARY ?
    return this.getDate().get('month') === 0;
  }).readOnly(),


  bySymbol(symbol) {
    let attribute = null;
    switch (symbol) {
      case "DC":
        attribute = 'classThree';
        break;
      case "GDK":
        attribute = 'classFour';
        break;
      case "CSC":
        attribute = 'cashSettledCheese';
        break;
      case "CB":
        attribute = 'cashSettledButter';
        break;
      case "GNF":
        attribute = 'nonfatDryMilk';
        break;
      case "DY":
        attribute = 'dryWhey';
        break;
      case "ZC":
        attribute = 'corn';
        break;
      case "ZM":
        attribute = 'soybeanMeal';
        break;
    }
    return attribute ? this.get(attribute) : 0;
  },

  getDate() {
    return toUtc(this.get('date'));
  }
});
