import Route from '@ember/routing/route';
import $ from 'jquery';
import ENV from 'agropur-client/config/environment';
import UnauthenticatedRouteMixin from 'ember-simple-auth/mixins/unauthenticated-route-mixin';

export default Route.extend(UnauthenticatedRouteMixin, {
  routeIfAlreadyAuthenticated: 'authenticated.dashboard',
    activate: function(){
        $.ajaxSetup({
          headers: {Authorization: ENV.APP.CLIENT_OAUTH }
        });
      },

      actions: {
         willTransition: function() {
          this.controller.set("errorMessage", null);
          $.ajaxSetup({
            headers: null
          });
        }
      }
});