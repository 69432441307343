import { underscore } from '@ember/string';
import DS from 'ember-data';

export default DS.JSONSerializer.extend({
  keyForAttribute(attr) {
    return underscore(attr);
  },
  extractId(modelClass, resourceHash) {
    resourceHash.id = `${resourceHash.instrument_id}_${resourceHash.side}`;
    return this._super(modelClass, resourceHash);
  },
  normalizeSingleResponse(store, primaryModelClass, payload, id, requestType){

    if(Array.isArray(payload)){
      payload = payload[0];
    } 

    if(payload === undefined){
      payload = {};
    }

    return this._super(store, primaryModelClass, payload, id, requestType);

  },
});