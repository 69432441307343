import Component from '@ember/component';
import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import { isPresent } from '@ember/utils';

export default Component.extend({
  classNames : ['panel', 'panel-default', 'vd-panel-with-reveal'],
  title      : null,
  hasReveal  : true,
  height     : '',

  maxHeight: computed('height', function() {
    const riskyString = `max-height: ${this.height} !important;`;
    return htmlSafe(riskyString);
  }),

  actions: {
    showSecondaryPanel() {
      this.$().find('.secondary-header,.secondary').show();
      this.$().find('.primary-header').hide();
      this.$().find('.primary').addClass('blured');

      if (isPresent(this.$().find('.secondary-content'))) {
        this.$().find('.content').css({ minHeight: this.$().find('.secondary-content').height() });
      }
    },

    hideSecondaryPanel() {
      this.$().find('.secondary-header,.secondary').hide();
      this.$().find('.primary-header').show();
      this.$().find('.primary').removeClass('blured');

      // Reset the content height
      this.$().find('.content').css({ minHeight: 'auto' });
    }
  }
});
