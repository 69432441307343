import Component from '@ember/component';

export default Component.extend({
  height       : 300,
  data         : null,

  init(...args) {
    this._super(...args);
    this.set('data', []);

    let sources =  [
      {
        name    : 'Dairy Farming Industry and Market News from MILK | Agweb.com',
        url     : 'http://www.milkbusiness.com/rss',
        logo    : 'http://www.milkbusiness.com/sites/default/files/FJ-Milk-header.png'
      }
    ];

    for (let i = 0; i < sources.length; i++){
      fetch(`https://api.rss2json.com/v1/api.json?rss_url=${sources[i].url}`).then(async (response)=>{
        let json = await response.json();
        let items = json.items;

        if(items.length > 3){
          items = items.slice(0, 3);
        }
        
        this.get('data').pushObjects(items);
      });
  
    }
  }

});
