
import { cancel, later } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';

export default Service.extend({
  session: service(),
  store: service(),
  pollTimer: null,
  pollFrequency: 60000,
  subscribedInstruments: null,

  init: function(){
      this._super(...arguments);
  },
  start: function(/*subscriber, */){
    if(this.get("pollTimer") !== null){
      cancel(this.get("pollTimer"));
    }
    this.marketDataPoll();
  },
  stop: function(/*subscriber*/){
    cancel(this.get("pollTimer"));
  },
  subscribeTo: function(instruments){
    this.set('subscribedInstruments', instruments);
  },
  marketDataPoll: function(){

    let instruments = this.get('subscribedInstruments');

    if(instruments && instruments.length > 0){
        let instrumentIds = instruments.mapBy("id");
        let length = instrumentIds.length;

        var current = 0;
        var groups = [];
        var end;

        while(current < length){
          end = length < current + 49 ? length : current + 49
          groups.pushObject(instrumentIds.slice(current,end));
          current += 50;
        }

        groups.forEach((g) => {
          this.store.query("marketdatum", {instrument_ids: g, sides: ["Last"]});
        });
    }

    this.set("pollTimer", later(this, this.marketDataPoll.bind(this), this.get("pollFrequency")));

  }
});
