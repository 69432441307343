import ENV from 'agropur-client/config/environment';
import $ from 'jquery';
import { computed } from '@ember/object';
import { isHTMLSafe } from '@ember/template';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { filterBy } from '@ember/object/computed';


export default Service.extend({
  store   : service(),
  session : service(),

  userNotifications: computed('', function() {
    return this.get('store').peekAll('user-notification');
  }),

  unreadUserNotifications: filterBy('userNotifications', 'status', 'UNREAD').readOnly(),

  readUserNotifications: filterBy('userNotifications', 'status', 'READ').readOnly(),

  init() {
    this._super(...arguments)
    // eslint-disable-next-line no-undef
    Noty.overrideDefaults({
      layout      : 'topRight',
      // theme       : 'bootstrap-v3', // mint sunset relax nest semanticui bootstrap-v3
      theme       : 'relax',
      // timeout     : false,
      timeout     : 1000,
      progressBar : false,
      closeWith   : ['click']
      // animation : {
      //   open  : 'animated fadeInRight',
      //   close : 'animated fadeOutRight'
      // }
    });
  },

  _createNoty(text, type) {
    // eslint-disable-next-line no-undef
    new Noty({
      text,
      type
    }).show();
  },

  _createNotyWithOptions(options) {
    // eslint-disable-next-line no-undef
    return new Noty(options).show();
  },

  _closeNoty() {
    // eslint-disable-next-line no-undef
    Noty.closeAll();
  },

  alert(text) {
    this._createNoty(text, 'alert');
  },

  success(text) {
    this._createNoty(text, 'success');
  },

  warning(text) {
    this._createNoty(text, 'warning');
  },

  error(text) {
    this._createNoty(text, 'error');
  },

  info(text) {
    this._createNoty(text, 'info');
  },

  custom(options) {
    this._createNotyWithOptions(options);
  },

  centerKiller(message) {
    let text = message;
    if (isHTMLSafe(text)) {
      text = message.string;
    }
    // eslint-disable-next-line no-undef
    return new Noty({
      text,
      type      : 'alert',
      modal     : true,
      killer    : true,
      layout    : 'center',
      timeout   : false,
      closeWith : [],
      animation : {
        open  : null,
        close : null
      }
    }).show();
  },

  _getXAuthToken() {
    return this.get('session.data.authenticated.auth');
  },

  _loadNotifications() {
    const token = this._getXAuthToken();
    return $.ajax({
      type        : 'GET',
      // url         : `${ENV.APP.HOST}/api/notifications/list/all/${this.get('application.organization.id')}`,
      url         : `${ENV.APP.HOST}/api/notifications`,
      contentType : 'application/json',
      dataType    : 'json',
      headers     : {
        'X-AUTH-TOKEN': token
      }
    }).then((payload) => {
      return this.get('store').pushPayload({ notifications: payload });
    });
  },

  _loadUserNotifications() {
    const token = this._getXAuthToken();
    return $.ajax({
      type        : 'GET',
      // url         : `${ENV.APP.HOST}/api/notifications/list/all/${this.get('application.organization.id')}`,
      url         : `${ENV.APP.HOST}/api/user-notifications`,
      contentType : 'application/json',
      dataType    : 'json',
      headers     : {
        'X-AUTH-TOKEN': token
      }
    }).then((payload) => {
      return this.get('store').pushPayload({ userNotifications: payload });
    });
  },

  _updateUserNotification(userNotification) {
    const token = this._getXAuthToken();
    return $.ajax({
      type        : 'PATCH',
      // url         : `${ENV.APP.HOST}/api/notifications/list/all/${this.get('application.organization.id')}`,
      url         : `${ENV.APP.HOST}/api/user-notifications/${userNotification.get('id')}`,
      contentType : 'application/json',
      dataType    : 'json',
      headers     : {
        'X-AUTH-TOKEN': token
      },
      data: userNotification
    }).then((payload) => {
      return this.get('store').pushPayload({ userNotifications: payload });
    });
  }

});
