import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default Route.extend(AuthenticatedRouteMixin, {
  // permissionService: service('permission'),

  // beforeModel() {
  //   if (!this.get('permissionService')._hasPermission('DAIRY_ADMIN')) {
  //     this.transitionTo('dashboard');
  //   }
  // },

  model(params) {
    return this.get('store').findRecord('dairy', params.id);
    // const store = this.get('store');
    // return hash({
    //   dairy            : store.findRecord('dairy', params.dairy_id),
    //   contacts         : store.query('dairy-contact', { dairy: params.dairy_id }),
    //   userDairyConfigs : store.query('user-dairy-config', { dairy: params.dairy_id })
    // });
  }

});
