import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';


export default Route.extend(AuthenticatedRouteMixin, {

  model(params) {

    let {organization} = this.modelFor('authenticated');

    return hash({
      organization,
      userOrganizationConfig: this.get('store').findRecord('user-organization-config', params.id)
    })

  },
  afterModel(model){
    if(!model.userOrganizationConfig.isOrganizationAdmin){
      model.userDairyConfigs = this.get('store').query('user-dairy-config', {user: model.userOrganizationConfig.get('userId.id'), organization: model.organization.id});
    }
    return model;
  }

});
