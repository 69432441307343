import DS from 'ember-data';

const { attr, belongsTo } = DS;

export default DS.Model.extend({
  instrumentSymbol: attr('string'),

  price     : attr('number'),
  side      : attr('string'),
  timestamp : attr('utcdatetime'),
  closeDate : attr('utcdate'),
  final     : attr('boolean'),

  instrument: belongsTo('vault-instrument')
});
