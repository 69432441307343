import { computed } from '@ember/object';
import moment from 'moment';
import Controller from '@ember/controller';
import DisplayModeMixin from 'agropur-client/mixins/display-mode';

export default Controller.extend(DisplayModeMixin, {

  chartPriceFormattiong: computed('model.displayMode', function() {
    return this.get('model.displayMode') == 'cwt' ? '$0.00' : '$0,0';
  }),

  chartData: computed('model.{months.@each.classThreeMilkPrice,displayMode}', 'application.organization.division', function() {
    let months = this.model.months.filter((month) => {
      return moment.utc(month.get('date')).isSameOrBefore(moment().add(12, 'months')) || month.get('agropurBasisPrice') !== 0;
    });

    let config = {columns: [], names: {}};

    config['columns'].push(['x'].concat(months.map((month) => {
      return moment.utc(month.get('date')).format('YYYY-MM-DD');
    })));

    config['columns'].push(['data1'].concat(months.map((month) => {

      if(this.get('model.displayMode') == 'cwt'){
        return month.get('classThreeMilkPrice');
      } else {
        return month.get('classThreeMilkPrice') * month.get('totalProductionCwt');
      }

    })));

    config['names']['data1'] = 'Class III Milk Price';



    if(this.get('model.organization.showAgropurBasis')){
      config['columns'].push(['data2'].concat(months.map((month) => {

        if(this.get('model.displayMode') == 'cwt'){
          return month.get('agropurForwardPrice');
        } else {
          return month.get('agropurForwardPrice') * month.get('totalProductionCwt');
        }

      })));

      config['names']['data2'] = 'Total Forward Price';
    }

    return config;

  }),

  // chartData: computed('model.months','displayMode','application.organization.division', function() {
  //   const formatPrice = (month, attr, dm, isTotal) => {
  //     if (month.get(attr)) {
  //       return formatNumberCwt([
  //         month.get(attr),
  //         '0.00',
  //         dm.perCWT,
  //         month.get('totalProductionCwt'),
  //         '0.00',
  //         dm.perCow,
  //         month.get('cowsInMilk'),
  //         isTotal
  //       ]) * 1;
  //     }
  //     return 0;
  //   };

  //   return this._agropurChartData(this.model.months, formatPrice, this.get('displayMode'), this.get('application.organization.division'));
  // }),

  // _agropurChartData(months, pFmt, dm, division) {
  //   const config = {};

  //   months = months.filter((month) => {
  //     const date = toUtc(month.get('date'));
  //     return date.isSameOrBefore(moment().add(12, 'months')) || month.get('agropurBasisPrice') !== 0;
  //   });

  //   if (division === 'Agropur - Jerome') {
  //     config.columns = [
  //       ['x'].concat(months.map((m) => {
  //         return format(toUtc(m.get('date')), 'YYYY-MM-DD');
  //       })),

  //       ['data1'].concat(months.map((m) => {
  //         return pFmt(m, 'classThreeMilkPrice', dm);
  //       })),

  //       ['data2'].concat(months.map((m) => {
  //         return pFmt(m, 'agropurForwardPrice', dm);
  //       }))
  //     ];

  //     config.names = {
  //       data1 : 'Class III Milk Price',
  //       data2 : 'Total Forward Price'
  //     };
  //   } else {
  //     config.columns = [
  //       ['x'].concat(months.map((m) => {
  //         return format(toUtc(m.get('date')), 'YYYY-MM-DD');
  //       })),

  //       ['data1'].concat(months.map((m) => {
  //         return pFmt(m, 'classThreeMilkPrice', dm);
  //       }))
  //     ];

  //     config.names = {
  //       data1 : 'Class III Milk Price'
  //     };
  //   }

  //   console.log(config);
  //   return config;
  // }

});
