import DS from 'ember-data';
import { computed } from '@ember/object';

const { Model, attr, hasMany, belongsTo } = DS;

export default Model.extend({
  name          : attr('string'),
  description   : attr('string'),
  slug          : attr('string'),
  logo          : attr('string'),
  location      : attr('string'),
  email         : attr('string'),
  phone         : attr('string'),
  createdDate   : attr('date'),
  division      : attr('string'),
  miscellaneous : attr(),

  dairies                 : hasMany('dairy'),
  userOrganizationConfigs : hasMany('user-organization-config'),

  ownerId: belongsTo('user'),

  showAgropurBasis: computed('division', function(){
    return this.division == "Agropur - Jerome";
  }),

  pricingModels: computed('miscellaneous.settings.pricingModels', 'isAgropur', function() {
    return ['AGROPUR'];
  })
});
