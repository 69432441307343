/* eslint-disable ember/no-function-prototype-extensions */
import DS from 'ember-data';
import {alias} from '@ember/object/computed';
import { computed } from '@ember/object';

export default DS.Model.extend({
    symbol:                     DS.attr("string"),
    name:                       DS.attr("string"),
    expiration_date:            DS.attr("date"),
    display_expiration_date:    DS.attr("date"),
    expiration_type:            DS.attr("string"),
    option_type:                DS.attr("string"),
    strike:                     DS.attr("number"),
    trading_status:             DS.attr("string"),
    product_id:                 DS.belongsTo('product'),
    product:                    alias("product_id"),
    instrument_type:            DS.attr("string"),
    spread_code:                DS.attr("string"),
    spread_description:         DS.attr("string"),

    //
    //  Return the most recent price available.
    //  If the instrument hasn't traded today, it will be the close price.
    //
    best: computed("last.price","close.price", function(){
        if(this.get("last.price") !== undefined){
            return this.get("last");
        } else {
            return this.get("close");
        }
    }),

    bestPrice: alias('best.price'),

    sessionPriceChange: computed("best.price", "close.price", function(){
        if(this.get("best.price") !== undefined && this.get("close.price") !== undefined){
            return this.get("best.price") - this.get("close.price");
        } else {
            return null;
        }

    }),

    sessionPriceChangePercentage: computed("sessionPriceChange", 'best.price' , function(){
        if(this.get("sessionPriceChange") !== null && this.get("best.price") !== 0){
        return this.get("sessionPriceChange") / this.get("best.price");
        }
    }),

    last:       DS.belongsTo("marketdatum", {async: true}),
    close:      DS.belongsTo("marketdatum", {async: true}),
    high:      DS.belongsTo("marketdatum", {async: true}),
    low:      DS.belongsTo("marketdatum", {async: true}),

    settlement_price: DS.attr("number"),
    historical_price: DS.attr('number'),

    underlyingInstrument: alias('underlying_id'),
    underlying_id: DS.belongsTo("instrument", {async: true})


});
