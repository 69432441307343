import $ from 'jquery';

export default {

  toggleChatlio(/* options = {} */) {
    if (this._isChatlioReady(window) /* && isPresent(options.property) */) {
      window._chatlio.show({ expanded: $('.chatlio-widget').hasClass('closed') });
    }
  },

  _isChatlioReady(window) {
    return typeof window !== 'undefined' && typeof window._chatlio === 'object';
  }

};
