import ENV from 'agropur-client/config/environment';
import Service from '@ember/service';
import { inject as service } from '@ember/service';
import $ from 'jquery';
import { Promise } from 'rsvp';
import { computed } from '@ember/object';

const { rdtvChannelId, agropurJeromePlaylistId, agropurOtherPlaylistId } = ENV.google.youtube;

export default Service.extend({
  store: service(),

  init() {
    this._super(...arguments);
    this.getPlaylistItems(agropurJeromePlaylistId);
    this.getPlaylistItems(agropurOtherPlaylistId);
    this.getChannelInfo(rdtvChannelId);
    this.getChannelVideos(rdtvChannelId);
    this.getChannelPlaylists(rdtvChannelId);
  },

  channels: computed('store', function() {
    return this.get('store').peekAll('tv-channel');
  }),

  playlists: computed('store', function() {
    return this.get('store').peekAll('tv-playlist');
  }),

  videos: computed('store', function() {
    return this.get('store').peekAll('tv-video');
  }),

  getChannelInfo(channelId) {
    return new Promise((resolve) => {
      let url = '';
      const googleKey = ENV.google.key;
      const youtubeHost = ENV.google.youtube.host;
      const parts = 'snippet,contentDetails,statistics';
      url = `${youtubeHost}/channels/?id=${channelId}&part=${parts}&maxResults=30&key=${googleKey}`;
      $.ajax({
        url,
        context : this,
        success : (resp) => {
          for (let i = 0; i < resp.items.length; i++) {
            const raw = resp.items[i];
            const channel = {
              id         : resp.items[i].id,
              type       : 'tv-channel',
              attributes : {
                title           : raw.snippet.title,
                description     : raw.snippet.description,
                customUrl       : raw.snippet.customUrl,
                publishedAt     : raw.snippet.publishedAt,
                thumbnails      : raw.snippet.thumbnails,
                viewCount       : raw.statistics.viewCount,
                subscriberCount : raw.statistics.subscriberCount,
                videoCount      : raw.statistics.videoCount
              }
            };
            this.get('store').push({ data: channel });
          }
          resolve();
        }
      });
    });
  },

  getChannelVideos(channelId) {
    return new Promise((resolve) => {
      let url = '';
      const googleKey = ENV.google.key;
      const youtubeHost = ENV.google.youtube.host;
      const type = 'video';
      const part = 'id,snippet';
      const order = 'date';
      const maxResults = 30;
      url = `${youtubeHost}/search?type=${type}&channelId=${channelId}&part=${part}&order=${order}&maxResults=${maxResults}&key=${googleKey}`;
      $.ajax({
        url,
        context : this,
        success : (resp) => {
          const channel = {
            id         : resp.items[0].snippet.channelId,
            type       : 'tv-channel',
            attributes : {
              title: resp.items[0].snippet.channelTitle
            }
          };
          this.get('store').push({ data: channel });
          for (let i = 0; i < resp.items.length; i++) {
            const raw = resp.items[i];
            const video = {
              id         : raw.id.videoId,
              type       : 'tv-video',
              attributes : {
                title       : raw.snippet.title,
                description : raw.snippet.description,
                publishedAt : raw.snippet.publishedAt,
                thumbnails  : raw.snippet.thumbnails
              },
              relationships: {
                channel: {
                  data: {
                    id   : raw.snippet.channelId,
                    type : 'tv-channel'
                  }
                }
              }
            };
            this.get('store').push({ data: video });
          }
          resolve();
        }
      });
    });
  },

  getChannelPlaylists(channelId) {
    return new Promise((resolve) => {
      let url = '';
      const googleKey = ENV.google.key;
      const youtubeHost = ENV.google.youtube.host;
      const parts = 'id,snippet';
      url = `${youtubeHost}/playlists/?channelId=${channelId}&part=${parts}&maxResults=30&key=${googleKey}`;
      $.ajax({
        url,
        context : this,
        success : (resp) => {
          for (let i = 0; i < resp.items.length; i++) {
            const raw = resp.items[i].snippet;
            const playlist = {
              id         : resp.items[i].id,
              type       : 'tv-playlist',
              attributes : {
                title       : raw.title,
                description : raw.description,
                publishedAt : raw.publishedAt,
                thumbnails  : raw.thumbnails
              },
              relationships: {
                channel: {
                  data: {
                    id   : raw.channelId,
                    type : 'tv-channel'
                  }
                }
              }
            };
            this.get('store').push({ data: playlist });
          }
          resolve();
        }
      });
    });
  },

  getPlaylistItems(playlistId) {
    return new Promise((resolve) => {
      let url = '';
      const googleKey = ENV.google.key;
      const youtubeHost = ENV.google.youtube.host;
      const parts = 'id,snippet';
      const maxResults = 30;
      url = `${youtubeHost}/playlistItems/?playlistId=${playlistId}&part=${parts}&maxResults=${maxResults}&key=${googleKey}`;
      $.ajax({
        url,
        context : this,
        success : (resp) => {
          const channel = {
            id         : resp.items[0].snippet.channelId,
            type       : 'tv-channel',
            attributes : {
              title: resp.items[0].snippet.channelTitle
            }
          };
          this.get('store').push({ data: channel });
          const playlist = {
            id         : resp.items[0].snippet.playlistId,
            type       : 'tv-playlist',
            attributes : {
              // title: resp.items[0].snippet.channelTitle
            }
          };
          this.get('store').push({ data: playlist });
          for (let i = 0; i < resp.items.length; i++) {
            const raw = resp.items[i].snippet;
            const video = {
              id         : raw.resourceId.videoId,
              type       : 'tv-video',
              attributes : {
                title       : raw.title,
                description : raw.description,
                publishedAt : raw.publishedAt,
                thumbnails  : raw.thumbnails,
                position    : raw.position
              },
              relationships: {
                channel: {
                  data: {
                    id   : raw.channelId,
                    type : 'tv-channel'
                  }
                },
                playlist: {
                  data: {
                    id   : raw.playlistId,
                    type : 'tv-playlist'
                  }
                }
              }
            };
            this.get('store').push({ data: video });
          }
          resolve();
        }
      });
    });
  }

});
