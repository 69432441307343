import DS from 'ember-data';
import DateUtils from 'agropur-client/utils/date-utils';
import { computed } from '@ember/object';

const { currentRollbackMonth } = DateUtils;

const { attr, belongsTo } = DS;

export default DS.Model.extend({

  instrument  : belongsTo('instrument', { inverse: null }),
  account     : belongsTo('account', { inverse: null }),
  quantity    : attr('number'),
  tradePrices : attr(),

  plInTicks: computed('quantity', 'tradePrices', 'instrument.{expiration,optionType,delayedPrice,settlementBestPrice}', function() {
    const currentPrice = this.get('instrument.delayedPrice') || this.get('instrument.settlementBestPrice');
    const currentMonth = currentRollbackMonth();

    return this.get('tradePrices').reduce((total, tp) => {
      if (currentMonth.isAfter(this.get('expiration'), 'month')) {
        if (tp.quantity > 0) {
          return total + (this.get('instrument.settlementBestPrice') - tp.price) * tp.quantity;
        }
        return total + (tp.price - this.get('instrument.settlementBestPrice')) * Math.abs(tp.quantity);
      }
      if (tp.quantity > 0) {
        return total + (currentPrice - tp.price) * tp.quantity;
      }
      return total + (tp.price - currentPrice) * Math.abs(tp.quantity);
    }, 0);
  }),

  plInDollars: computed('plInTicks','instrument.{expiration,type,product.tickSize,product.tickValue,underlying}', function() {
    const currentMonth = currentRollbackMonth();
    if (this.get('instrument.type') === 'Option' && currentMonth.isAfter(this.get('instrument.expiration'), 'month')) {
      return this.get('plInTicks') / this.get('instrument.underlyingproduct.tickSize') * this.get('instrument.underlying.product.tickValue');
    }
    return this.get('plInTicks') / this.get('instrument.product.tickSize') * this.get('instrument.product.tickValue');
  }),

  premiumInTicks: computed('quantity', 'tradePrices', 'instrument.{expiration,type}', function() {
    return this.get('tradePrices').reduce((total, tp) => {
      if (tp.quantity > 0 && this.get('instrument.type') === 'Option') {
        return total + (tp.price * tp.quantity);
      }

      if (tp.quantity < 0 && this.get('instrument.type') === 'Option') {
        return total - (tp.price * Math.abs(tp.quantity));
      }
      return total;
    }, 0);
  }),

  premiumInDollars: computed('premiumInTicks','instrument.{expiration,type,product.tickSize,product.tickValue,underlying}', function() {
    const currentMonth = currentRollbackMonth();
    if (this.get('instrument.type') === 'Option' && currentMonth.isAfter(this.get('instrument.expiration'), 'month')) {
      return this.get('premiumInTicks') / this.get('instrument.underlying.product.tickSize') * this.get('instrument.underlying.product.tickValue');
    }
    return this.get('premiumInTicks') / this.get('instrument.tickSize') * this.get('instrument.tickValue');
  }),

  averagePurchasePrice: computed('tradePrices', function() {
    const totalQuantity = this.get('tradePrices').reduce((prev, obj) => (prev + Math.abs(obj.quantity)), 0);
    return this.get('tradePrices').reduce((prev, obj) => (prev + (obj.price * Math.abs(obj.quantity))), 0) / totalQuantity;
  }),

  quantityInUnits: computed('instrument.{product.contractSize,underlying.product.contractSize}', 'quantity', function() {
    const size = this.get('instrument.underlying.product.contractSize') > 0 ? this.get('instrument.underlying.product.contractSize') : this.get('instrument.product.contractSize');
    return size * Math.abs(this.get('quantity'));
  }),

  isLong: computed('quantity', function() {
    return this.get('quantity') >= 0;
  })
});
