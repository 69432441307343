import Controller from '@ember/controller';

export default Controller.extend({
  actions: {

    deleteMembership(member) {
      member.destroyRecord();
    }
  }

});
