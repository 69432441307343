
import { computed } from '@ember/object';
import Controller from '@ember/controller';

import PeriodMixin from 'agropur-client/mixins/period';
import DisplayModeMixin from 'agropur-client/mixins/display-mode';

import { A } from '@ember/array';


export default Controller.extend(PeriodMixin, DisplayModeMixin, {

  newPositionType: 'FORWARD',
  newPositionPrice: null,
  newPositionStrike: null,
  newPositionQuantity: null,
  newPositionLong: null,
  newTicketNumber: null,

  init(){
    this._super(...arguments);
    this.set('selectedMonths', A());
  },

  checkedMonths: computed.filterBy('model.selectableMonths', 'checked', true),

  partialMonthsSelected: computed('checkedMonths.@each.checked', function(){
    return this.checkedMonths.length > 0 && this.checkedMonths.length < this.model.months.length
  }),

  allMonthsSelected: computed('checkedMonths.@each.checked', function(){
    return this.checkedMonths.length == this.model.months.length;
  }),

  clearNewPosition: function(){
    this.set('newPositionType', 'FORWARD');
    this.set('newPositionPrice', null);
    this.set('newPositionStrike', null);
    this.set('newPositionQuantity', null);
    this.set('newPositionLong', null);
    this.set('newTicketNumber', null);
  },

  actions: {
    saveRecords() {
      const list     = this.get('checkedMonths');


      const type     = this.get('newPositionType');
      const price    = this.get('newPositionPrice');
      const strike   = this.get('newPositionStrike');
      const quantity = this.get('newPositionQuantity');
      const long     = this.get('newPositionLong') === 'true';
      const instrument_id = this.get('newTicketNumber');

      if (list.length != 0) {
        list.forEach((el) => {
          const e = this.get('store').createRecord('manual-position', {
            positionType: 'PHYSICAL',
            type,
            price,
            strike,
            quantity,
            long,
            instrument_id
          });

          e.set('month', el.get('month'));
          e.set('product_id', 'DC');
          e.set('user_id', this.model.currentUserId);

          e.save();
        });

        this.toggleProperty('showPositionsModal');
        this.checkedMonths.forEach((m) => {
          m.set('checked', false);
        });
        this.clearNewPosition();

      }
    },

    toggleCreateModal() {
      this.clearNewPosition();

      this.toggleProperty('showPositionsModal');
    },


    cancelPositionsModal() {
      this.clearNewPosition();
      this.checkedMonths.forEach((m) => {
        m.set('checked', false);
      });
      this.toggleProperty('showPositionsModal');

    },


    updateAllSelectedMonths(){

      if(this.checkedMonths.length > 0 && !this.partialMonthsSelected){
        this.checkedMonths.forEach((m) => {
          m.set('checked', false);
        });
      } else {
        this.model.selectableMonths.forEach((m) => {
          m.set('checked', true);
        });
      }
    }
  }

});
