import DS from 'ember-data';

const { JSONSerializer } = DS;

export default JSONSerializer.extend({
  serialize(...args) {
    const json = this._super(...args);
    json.monthId = json.month_id;
    json.productId = json.product_id;
    json.userId = json.user_id;
    json.positionType = 'PHYSICAL';
    json.position_type = 'PHYSICAL';
    json.instrumentId = json.instrument_id;

    return json;
  }
});
