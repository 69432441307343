import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';

export default Route.extend(AuthenticatedRouteMixin, {

  model() {
    let {organization, isOrgAdmin, dairies} = this.modelFor('authenticated');

    return hash({
      organization,
      userOrganizationConfigs : this.get('store').query('user-organization-config', { organization_id: organization.id }),
      users: this.get('store').findAll('user'),
      dairies,
      isOrgAdmin
    });
  }

});
