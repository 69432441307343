import DS from 'ember-data';

const { JSONSerializer } = DS;

export default JSONSerializer.extend({
  serialize(...args) {
    const json = this._super(...args);
    json.config = JSON.stringify(json.config);
    json.miscellaneous = JSON.stringify(json.miscellaneous);
    json.producerId = json.producerId.toString();
    return json;
  },

  deserialize(...args) {
    const json = this._super(...args);
    json.producerId = parseInt(json.producerId, 10);
    return json;
  }
});
