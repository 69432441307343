import DS from 'ember-data';
import { computed } from '@ember/object';

const { Model, attr, belongsTo } = DS;

export default Model.extend({
  defaultDairyId      : attr('number'),
  miscellaneous       : attr(),
  permissions         : attr(),

  userId         : belongsTo('user'),
  organizationId : belongsTo('organization'),

  isOrganizationOwner: computed('organizationId.ownerId.id', 'userId.id', function() {
    return this.get('organizationId.ownerId.id') === this.get('userId.id');
  }),

  isOrganizationAdmin: computed('permissions.[]', function() {
    return this.get('permissions').includes(300);
  }),

  organizationRole: computed('isOrganizationOwner', 'isOrganizationAdmin', function() {
    if (this.get('isOrganizationOwner')) {
      return 'Owner';
    }
    if (this.get('isOrganizationAdmin')) {
      return 'Admin';
    }
    return 'Member';
  })
});
