import Component from '@ember/component';
import BodyEventListener from '../../../mixins/body-event-listener';
import $ from 'jquery';

export default Component.extend(BodyEventListener, {

  isShown: false,

  init: function() {
    this._super(arguments);
  },

  bodyClick: function() {
    this.send('hideDropdown');
  },

  _hideDropdown: function() {
    if(this.get('isShown')) {
      var parent = $(this.$()[0]).find('.select');
      parent.removeClass('is-open');
      this.set('isShown', false);
    }
  },

  didInsertElement: function() {
    var self = this;
    $(this.$()[0]).on('click','.placeholder', function(evt) {
      var parent = $(this).closest('.select');
      if ( ! parent.hasClass('is-open')) {
        self.set('isShown', true);
        parent.addClass('is-open');
        $('.select.is-open').not(parent).removeClass('is-open');
      } else {
        self._hideDropdown();
      }
      evt.preventDefault();
    }).on('click','ul>li', function(evt) {
      var parent = $(this).closest('.select');
      var label  = $(this).text();
      var value  = $(this).attr('data-value');

      if(self.get('beforeSelect') && self.get('beforeSelect').apply(self.get('scope'), [value, self.get('target')])) {
        parent.find('.placeholder').text(label);
        parent.find('input[type=hidden]').attr('value', value);
        self._updateValue(value);
      } else if (!self.get('beforeSelect')) {
        parent.find('.placeholder').text(label);
        parent.find('input[type=hidden]').attr('value', value);
        self._updateValue(value);
      }

      self._hideDropdown();
      evt.preventDefault();
    });

    this._setComboValue();

    this._super(arguments);
  },

  _setComboValue: function() {
    var comboValue = -1;
    if(this.get('model')) {
      comboValue = this.get('model').get(this.get('attribute'));
    } else if (this.get('selected') != null) {
      comboValue = this.get('selected');
    }

    var el = $(this.$()[0]);
    el.find('input[type=hidden]').attr('value', comboValue);
    el.find('.placeholder').empty().text(
      el.find('li[data-value=' + comboValue + ']').text().trim()
    );
  },

  _updateValue: function(value) {
    if(this.get('model')) {
      this.get('model').set(this.get('attribute'), value);
      if(this.get('model').save) {
        this.get('model').save();
      }
    } else {
      this.set('target', value);
    }
  },

  actions: {
    hideDropdown: function() {
      this._hideDropdown();
    }
  }
});
