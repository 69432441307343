import moment from 'moment';

export default {

  isPastWithRollback() {
    const thisMonth = moment();
    const startDate = null;
    if (thisMonth.date() < this.get('rollAfterDay')) {
      startDate.substract(1, 'months');
    }
  },

  currentRollbackMonth() {
    const thisMonth  = moment();
    if (thisMonth.date() < 8) {
      return thisMonth.startOf('month').subtract(1, 'months');
    }
    return thisMonth.startOf('month');
  },

  getCurrentMonth() {
    return moment();
  },

  getCurrentDatetime() {
    return moment();
  },

  toUtc(date) {
    return moment.utc(date);
  },

  format(date, format) {
    if (date instanceof moment) {
      return date.format(format);
    }
    return moment(date).format(format);
  },

  dateInput(date) {
    if (date instanceof moment) {
      return date.format('YYYY-MM-DD');
    }
    return moment(date).format('YYYY-MM-DD');
  }

};
