import DS from 'ember-data';
import DateUtils from 'agropur-client/utils/date-utils';
import { computed } from '@ember/object';
import moment from 'moment';

const { currentRollbackMonth, toUtc } = DateUtils;

const { attr, hasMany, belongsTo } = DS;

export default DS.Model.extend({
  product: belongsTo('vault-product'),

  vaultMarketData: hasMany('vault-market-datum'),

  expiration : attr('utcdate'),
  externalId : attr('number'),
  symbol     : attr('string'),
  name       : attr('string'),
  type       : attr('string'),
  optionType : attr('string'),

  delayedPrice    : attr('number', { defaultValue: 0 }),
  closePrice      : attr('number', { defaultValue: 0 }),
  settlementPrice : attr('number', { defaultValue: 0 }),
  strike          : attr('number', { defaultValue: 0 }),
  bid             : attr('number', { defaultValue: 0 }),
  ask             : attr('number', { defaultValue: 0 }),

  high : attr('number', { defaultValue: 0 }),
  low  : attr('number', { defaultValue: 0 }),

  // Temporary, will be removed in future
  _externalUnderlyingId: attr('number'),


  _timer         : null,

  init(){
    this._super(...arguments);
    this.set('_pendingPrices', []);
    this.set('vaultMarketDataSorting', ['timestamp']);
  },

  // using standard ascending sort

  sortedVaultMarketData  : computed.sort('vaultMarketData', 'vaultMarketDataSorting'),

  lastMarketDatum: computed('sortedVaultMarketData', function() {
    return this.get('sortedVaultMarketData').get('lastObject');
  }),

  lastPrice: computed('lastMarketDatum', function() {
    return this.get('lastMarketDatum') ? this.get('lastMarketDatum.price') : 0;
  }),

  price: computed('lastPrice', function() {
    return this.get('lastPrice');
  }),

  scenarioPrice: computed('price', 'scenario.price', function() {
    return this.get('scenario.price') || this.get('price');
  }),

  settlementBestPrice: computed('settlementPrice', 'closePrice', function() {
    return this.get('settlementPrice') || this.get('closePrice');
  }),

  dateCode: computed('', function() {
    return this.getExpirationDate().format('YYMM');
  }),

  date: computed('expiration', function(){
    return moment(this.expiration).format("YYYY-MM-01");
  }),

  sessionPriceChange: computed('delayedPrice', 'closePrice', 'settlementBestPrice', function() {
    let current = this.get('delayedPrice');
    const last = this.get('settlementBestPrice');
    if (this.get('delayedPrice') === 0) {
      current = this.get('closePrice');
    }

    if (current > 0 && last > 0) {
      return current - last;
    }
    return 0;
  }),

  sessionPriceChangePercentage: computed('delayedPrice', 'closePrice', 'settlementBestPrice', function() {
    let current = this.get('delayedPrice');
    const last = this.get('settlementBestPrice');
    if (this.get('delayedPrice') === 0) {
      current = this.get('closePrice');
    }

    if (current > 0 && last > 0) {
      return (current - last) / last;
    }
    return 0;
  }),

  shouldDisplay: computed('expiration', function() {
    return this.getExpirationDate().isSameOrAfter(currentRollbackMonth(), 'month');
  }),

  isExpired: computed('expiration', function() {
    return this.getExpirationDate().isBefore(currentRollbackMonth(), 'month');
  }),

  isFirstOfTheYear: computed('expiration', function() {
    // IS JANUARY ?
    return this.getExpirationDate().get('month') === 0;
  }),

  getExpirationDate() {
    return toUtc(this.get('expiration'));
  }
});
