import Component from '@ember/component';
import { debounce } from '@ember/runloop';

export default Component.extend({

  dairy: null,

  searchTerm    : null,
  searchPromise : null,

  _serachLocations() {
    this.set('searchPromise',
      this.get('application')
        .searchLocation(this.get('searchterm'))
        .then(this._groupResults)
    );
  },

  _groupResults(list) {
    // This array will contain the reference to map group objects
    const result = [];

    // Group the list by 'state' attr\
    /* eslint-disable no-param-reassign */
    list.reduce((map, loc) => {
      if (!map[loc.state]) {
        result.push(map[loc.state] = {
          groupName : loc.state,
          options   : [loc]
        });
      } else {
        map[loc.state].options.push(loc);
      }
      return map;
    }, {});
    /* eslint-enable no-param-reassign */
    return result;
  },

  searchMatcher(location, term) {
    return (`${location.city.toUpperCase()} ${location.county.toUpperCase()} ${location.state.toUpperCase()}`).indexOf(term.toUpperCase());
  },

  _updateLocation(location) {
    let loc = location;
    const dairy = this.get('dairy');

    if (!this.get('application.store').hasRecordForId('location', loc.get('id'))) {
      loc = this.get('application.store').createRecord('location', {
        id: location.id,
        city: location.city,
        county: location.county,
        state: location.state
      });
    } else {
      loc = this.get('application.store').peekRecord('location', loc.get('id'));
    }

    dairy.set('locationId', loc);
    dairy.set('city', loc.get('city'));
    dairy.set('county', loc.get('county'));
    dairy.set('state', loc.get('state'));
  },

  actions: {
    doSearch(searchterm) {
      this.set('searchterm', searchterm);
      debounce(this, this._serachLocations, 350);
    },

    updateDairyLocation(location) {
      return this._updateLocation(location);
    },

    closeSearch() {
      this.set('searchterm', null);
      this.set('searchPromise', []);
    }
  }
});
