import Mixin from '@ember/object/mixin';


export default Mixin.create({


  clearApplicationCache() {
    // Clear all caches


    if (localStorage && localStorage.clear) {
      localStorage.clear();
    }
  },

  clearDairyCache() {

  },

  clearOrganizationCache() {
  
  }
});
