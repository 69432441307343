import Controller from '@ember/controller';


export default Controller.extend({
  dairy: null,

  

  init() {
    this._super(...arguments);
    const _onSaveNote = this.get('onSaveNote').bind(this);

    this.set('customButtons', []);

    const saveNoteButton = function() {
      const ui = window.$.summernote.ui;

      const button = ui.button({
        contents : '<i class="fa fa-floppy-o"/>',
        tooltip  : 'Save Note',
        click    : _onSaveNote
      });

      return button.render();
    };

    this.customButtons.push(saveNoteButton);
  },

  onSaveNote() {
    this.send('saveModel', this.get('dairy'));
  }

});
