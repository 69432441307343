import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';

export default Route.extend(AuthenticatedRouteMixin, {
    model() {

        const {periodDate, dairy, organization, isOrgAdmin} = this.modelFor('authenticated');

        return hash({
            periodDate,
            organization,
            isOrgAdmin,
            months: this.get('store').query('month', {start_date: periodDate.format("YYYY-MM-DD"), end_date: moment(periodDate).add(2, 'years').format("YYYY-MM-DD"), dairy_id: dairy.id})
        });

    },
    afterModel(model){
        model.months = model.months.sortBy('date');
    }
});
