import AuthenticatedRouteMixin from 'ember-simple-auth/mixins/authenticated-route-mixin';
import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import moment from 'moment';

export default Route.extend(AuthenticatedRouteMixin, {

  model(params) {
    const {dairy, basisInstruments, currentUserId, isOrgAdmin} = this.modelFor('authenticated');
    return hash({
      manualPositions: this.get('store').query('manual-position', {dairy_id: dairy.id, product_id: 'BASIS'}),
      month: this.get('store').findRecord('month', params.id),
      basisInstruments,
      currentUserId,
      isOrgAdmin
    });
  },
  afterModel(model){
    let basisInstrument = model.basisInstruments.find((e)=> {
      return moment.utc(e.get('expiration')).isSame(model.month.date, 'month');
    });
    model.month.set('agropurBasisInstrument', basisInstrument);

  },
  actions: {
    refreshModel(){
      this.refresh();
    }
  }
});
