import DS from 'ember-data';
import moment from 'moment';

export default DS.Transform.extend({
  deserialize(serialized) {
    return moment.utc(serialized).toDate();
  },

  serialize(deserialized) {
    return moment.utc(deserialized).format('YYYY-MM-DD');
  }
});
