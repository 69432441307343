import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';


export default Component.extend({
  // permission: service(),

  postContent: null,

  contentHeight: 800,

  editingDisabled: computed('permission.availablePermissions', function() {
    return  false; //!this.get('permission')._hasPermission('DAIRY_ADMIN');
  }),

  init(){
    this._super(...arguments);

    this.set('toolbarOptions', {
      style: {
        style: true
      },
      font: {
        bold          : true,
        italic        : true,
        underline     : true,
        superscript   : false,
        subscript     : false,
        strikethrough : false,
        clear         : true
      },
      fontname: {
        fontname: false
      },
      fontsize: {
        fontsize: true
      },
      color: {
        color: true
      },
      para: {
        ul        : true,
        ol        : true,
        paragraph : true
      },
      height: {
        height: false
      },
      table: {
        table: true
      },
      insert: {
        link    : true,
        picture : false,
        video   : false,
        hr      : true
      },
      view: {
        fullscreen : true,
        codeview   : false
      },
      help: {
        help: true
      }
    });

    this.set('customButtons', []);

    this.set('callbackOptions', {});
  },

  actions: {
    onContentChange(text) {
      this.set('postContent', text);
    }
  }
});
