import DS from 'ember-data';
import { underscore } from '@ember/string';

export default DS.JSONSerializer.extend({
    modelNameFromPayloadKey(){
      return this._super('productgroup');
    },
    keyForAttribute(attr) {
      return underscore(attr);
    }
});