import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Controller.extend({
  newModel: null,

  userOrganizationConfigs: alias('model.userOrganizationConfigs'),

  queryParams: ['sortProperties', 'sortAscending', 'searchAttr', 'searchTerm'],

  organizationPermissions: null,

  filter      : 'all',

  init(){
    this._super(...arguments);
    this.set('permissions', ['DAIRY_READ', 'DAIRY_WRITE']);
    this.set('searchAttrs', ['name', 'phone', 'email']);
    this.set('organizationPermissions', [{
      label : 'Admin',
      code  : 300
    },{
      label : 'Member',
      code  : 302
    }]);
  },

  searchAttr  : 'name',
  searchTerm  : '',

  _getTestString(userOrganizationConfig, searchAttr) {
    let testString = '';

    switch (searchAttr) {
      case 'name':
        testString = userOrganizationConfig.get('userId.fullName');
        break;
      case 'phone':
        testString = userOrganizationConfig.get('userId.phone');
        break;
      case 'email':
        testString = userOrganizationConfig.get('userId.email');
        break;
    }

    return testString || '';
  },

  filteredUserOrganizationConfigs: computed('userOrganizationConfigs.[]', 'searchAttr', 'searchTerm', 'application.userOrganizationConfig', function() {
    const searchAttr = this.get('searchAttr');
    const searchTerm = this.get('searchTerm');

    return this.get('userOrganizationConfigs').filter(function(userOrganizationConfig,) {
      const testString = searchTerm.length > 0 ? this._getTestString(userOrganizationConfig, searchAttr, searchTerm) : '';
      return testString.toLowerCase().includes(searchTerm.toLowerCase());
    }, this);
  }),

  sortProperties: 'name',

  sortProperty: computed('sortProperties', function() {
    return [this._getSortProperty(this.get('sortProperties'))];
  }),

  _getSortProperty(sortProperties) {
    let property = '';

    switch (sortProperties) {
      case 'name':
        property = 'userId.content.fullName';
        break;
      case 'phone':
        property = 'userId.content.phone';
        break;
      case 'email':
        property = 'userId.content.email';
        break;
    }

    return property || '';
  },

  sortAscending: true,

  userOrganizationConfigSorting: computed('sortProperty', 'sortAscending', function() {
    let sortProperty = this.get('sortProperty');
    sortProperty = typeof sortProperty === 'string' ? new Array(sortProperty) : sortProperty;
    const sort = this.get('sortAscending') ? sortProperty[0] : sortProperty[0].concat(':desc');
    return new Array(sort);
  }),

  sortedUserOrganizationConfigs: computed.sort('filteredUserOrganizationConfigs', 'userOrganizationConfigSorting'),


  organizationDairies: computed('model.dairies.[]', 'organization_id', function(){
    return this.model.dairies.filter((d)=>{
        return d.get('organizationId.id') == this.model.organization.get('id');
    }).sortBy('name');
}),

  _getDairyFromIndex(index) {
    return this.get('dairyService.availableDairies').objectAt(index);
  },

  actions: {
    openAddMemberModal(organization) {
      this.toggleProperty('showCreateModal');
      const member = this.get('store').createRecord('user-organization-config', {
        userId: null,
        organizationId: organization,
        defaultDairyId: null,
        miscellaneous: {
          addOrganizationPermissions: {
            label : 'Member',
            code  : 302
          },
          addDairyPermissions: []
        },
        permissions: []
      });
      this.set('memberAdditionModel', member);
    },

    addMember(member) {
      const dairiesToAdd = member.get('miscellaneous.addDairyPermissions').mapBy('id');
      member.set('miscellaneous.dairiesToAdd', dairiesToAdd);
      if (member.get('miscellaneous.addOrganizationPermissions.label') === 'Admin') {
        member.set('permissions', [300, 302]);
      } else {
        member.set('permissions', [302]);
      }
      member.save().then(() => {
        this.model.userOrganizationConfigs.update();
      });
      this.toggleProperty('showCreateModal');
    },

    rollback(member) {
      member.rollbackAttributes();
      this.toggleProperty('showCreateModal');
    },

    deleteMember(member) {
      member.destroyRecord();
    }
  }
});
