import DS from 'ember-data';
import $ from 'jquery';
import ENV from 'agropur-client/config/environment';
import { isPresent } from '@ember/utils';
import DataAdapterMixin from 'ember-simple-auth/mixins/data-adapter-mixin';

export default DS.RESTAdapter.extend(DataAdapterMixin, {
    host: ENV.APP.OPTIONSHOP_HOST,
    authorize(xhr) {
      let { access_token } = this.get('session.data.authenticated');
      if (isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
      }
    },
    coalesceFindRequests: true,
    findRecord: function(store, type, value) {
        var arr = value.split("_");
        return this.ajax(this.buildURL(type.modelName, null, null, "query"), "GET", {
            data: {
                instrument_ids: arr[0],
                sides: arr[1]
            }
        })
    },

    findMany: function(store, type, ids, snapshots){

      let str = "";

      let instrument_ids = ids.map((id)=>{
        return id.split('_')[0];
      });

      let sides = ids.map((id) => {
        return id.split('_')[1];
      });

      let uniqueInstruments = instrument_ids.uniq();
      let uniqueSides = sides.uniq();


      uniqueInstruments.forEach((instrument_id)=>{
        if(str.length != 0){
          str += "&";
        }
        str += "instrument_ids=" + instrument_id;
      });

      uniqueSides.forEach((side)=>{
        if(str.length != 0){
          str += "&";
        }
        str += "sides=" + side;
      });

      return this.ajax(this.buildURL(type.modelName, ids, snapshots, "query"), "GET", {
        data: str
      });

    },


    query: function(store, type, query) {

      var str = "";

      $.each(query, function(key, value) {

        if(0 !== str.length ){
          str += "&";
        }

        if(value instanceof Array){
          value.forEach(function(val, n) {
            if(0 !== n){
              str += "&";
            }
            str += key + "=" + val
          });
        } else {
          str += key + "=" + value
        }
      });

      return this.ajax(this.buildURL(type.modelName, null, null, "query"), "GET", {
          data: str
      })
  }

});