import DS from 'ember-data';

const { JSONSerializer } = DS;

export default JSONSerializer.extend({
  serialize(...args) {
    const json = this._super(...args);
    // Server expect 'miscellaneous' attr to be a String
    json.miscellaneous = JSON.stringify(json.miscellaneous);
    return json;
  }
});
