import { htmlSafe } from '@ember/template';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({

  classNames : ['panel', 'panel-default', 'vd-panel-with-reveal'],
  title      : null,
  height     : '',

  maxHeight: computed('height', function() {
    const riskyString = `max-height: ${this.height} !important;`;
    return htmlSafe(riskyString);
  })

  // mouseEnter(event) {
  //   console.log('mouseEnter event on vd-panel: ', event);
  // }
});
