import { helper } from '@ember/component/helper';
import { isPresent } from '@ember/utils';

export function formatNumber(params) {
  const [value, format] = params;

  if (isPresent(value) && isPresent(format)) {
    return numeral(value).format(format);
  }
  return value;
}

export default helper(formatNumber);
