
import ApplicationSerializer from './application';
import moment from 'moment';

export default ApplicationSerializer.extend({
  normalize(model, hash /*, prop*/){
    if(moment().subtract(1, 'month').isAfter(hash.date)){
      hash.historicalPriceId = hash.date.replace(/-/g,'');
    }

    return this._super(...arguments);
  }

});
