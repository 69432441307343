import DS from 'ember-data';

const { Model, attr } = DS;

export default Model.extend({
  city                       : attr('string'),
  classOneDifferential       : attr('number'), // TODO: Remove this
  county                     : attr('string'),
  manualClassOneDifferential : attr('number', { defaultValue: 0 }), // TODO: Remove this
  state                      : attr('string')
});
