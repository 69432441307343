import ENV from 'agropur-client/config/environment';
import $ from 'jquery';
import Service from '@ember/service';
import { inject as service } from '@ember/service';


export default Service.extend({
  store       : service(),


  createUserOrganizationConfig() {

    const userOrganizationConfig = this.get('store').createRecord('user-organization-config', {});

    return userOrganizationConfig;
  },

  addMemberPermissions() {
    const organization = this.get('application.organization');

    const organizationDefaults = organization.get('miscellaneous');

    const dairy = this.get('store').createRecord('dairy', {
      name         : `${organization.get('name').split(' ')[0]} - Default Dairy` || 'Default Dairy',
      vaultType    : organizationDefaults.get('defaultVaultType') || 'PRO',
      pricingModel : organizationDefaults.get('defaultPricingModel') || 'FMMO',
      quotaType    : 'MONTHLY',
      quotaValue   : 0,
      location     : null,
      config       : {
        rdAccount: ''
      },
      miscellaneous: {
        street: ''
      },
      notes      : 'Enter notes here...',
      basisType  : this.get('application.constants.BASIS_TYPES').get('firstObject'),
      basisRange : 12,
      basisValue : 0
    });

    dairy.set('locationId', this.get('application.dairy.locationId.content'));

    return dairy;
  },

  _inviteUser() {
    return $.ajax({
      url    : `${ENV.APP.HOST}api/dairies/switch?dairyId=${this.get('dairy').id}`,
      method : 'GET'
    }).then(() => {
      // eslint-disable-next-line ember/jquery-ember-run
      this.get('store').unloadAll();
      // eslint-disable-next-line ember/jquery-ember-run
      this.clearDairyCache();
    }).then(() => {
      window.location.reload();
    });
  },

  actions: {
    inviteUser(email) {
      this._inviteUser(email);
    }
  }
});
