import DS from 'ember-data';
import { underscore } from '@ember/string';

export default DS.RESTSerializer.extend({
    modelNameFromPayloadKey(){
      return this._super('instrument');
    },
    keyForAttribute(attr) {
      return underscore(attr);
    },

    normalizeArrayResponse(store, primaryModelClass, payload,  /* id, requestType */){


      let jsonDocument = {
        data: [],
        included: []
      };

      for (let i = 0; i < payload.length; i++) {
        const instrument = payload[i];
        if(instrument.product){

          if(instrument.product.group){
            jsonDocument.included.push(store.serializerFor('productgroup').normalize(store.modelFor('productgroup'), instrument.product.group)['data']);
            delete instrument.product.group;
          }

          jsonDocument.included.push(store.serializerFor('product').normalize(store.modelFor('product'), instrument.product)['data']);
          delete instrument.product;
        }

        jsonDocument.data.push(this.normalize(primaryModelClass, instrument)['data']);
      }

      return jsonDocument;

    },

    normalizeSingleResponse(/* store, primaryModelClass, payload, id, requestType */){
      this._super(...arguments);
    },

    normalize(model, hash /*, prop*/){
      hash.bid = hash.id + "_Buy";
      hash.ask = hash.id + "_Sell";
      hash.last = hash.id + "_Last";
      hash.close = hash.id + "_Close";
      hash.high = hash.id + "_High";
      hash.low = hash.id + "_Low";
      hash.open_interest = hash.id + "_OpenInterest";
      hash.volume = hash.id + "_Volume";
      hash.scenario = hash.id;
      return this._super(...arguments);
    }

  });