import Component from '@ember/component';
import moment from 'moment';
import { computed } from '@ember/object';


export default Component.extend({
    displayYear: computed('periodDate', function() {
        return moment(this.get('periodDate')).format('YYYY');
    }),
    actions: {
        changePeriod(num, value) {
            this.changePeriod(num, value);
        },
    }

});
