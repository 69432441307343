import { computed } from '@ember/object';
import Component from '@ember/component';
import { isPresent } from '@ember/utils';
import { htmlSafe } from '@ember/template';

const VdStaticCellComponent = Component.extend({
  tagName: 'td',

  object      : null,
  value       : null,
  displayMode : null,
  cwtformat   : null,
  format      : null,
  total       : false,
  validate    : false,
  attribute   : null,
  zero        : null, // 'dash'

  isInvalid: computed('object', 'value', function() {
    return !this.get('object') || (!this.get('value') && this.get('value') !== 0);
  }),

  isZero: computed('object', 'value', function() {
    return isPresent(this.get('value')) && isFinite(this.get('value')) && this.get('value') === 0;
  }),

  warning: computed('object', 'attribute', function() {

    const warning = !this.get('object.hasValidMilkProduction') ? htmlSafe(`<span class="text-danger" title="Invalid value for '${this.get('attribute')}'. Check this month's milk production."><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>`) : null;

    return warning || htmlSafe(`<span class="text-danger" title="Invalid value for '${this.get('attribute')}'."><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></span>`);
  })

});

export default VdStaticCellComponent;
