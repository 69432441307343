import DS from 'ember-data';

export default DS.Model.extend({
  name              : DS.attr('string'),
  externalId        : DS.attr('number'),
  organization      : DS.attr('string'),
  clearingHouse     : DS.attr('string'),
  accountNumber     : DS.attr('string'),
  netLiquidating    : DS.attr('number'),
  profitAndLoss     : DS.attr('number'),
  cashBalance       : DS.attr('number'),
  marginRequirement : DS.attr('number'),
  tradingEnabled    : DS.attr('boolean')
});
